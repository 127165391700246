<template>
    <div v-if="getUser.role.slug === 'super-administrateur' ||
        getUser.role.slug === 'responsable-relation-client' ||
        getUser.role.slug === 'comptable' ||
        getUser.role.slug === 'assistante-administrative' ||
        getUser.role.slug === 'charge-daffaires'
        ">
        <b-card>
            <b-tabs pills>
                <b-tab active>
                    <template #title>
                        <feather-icon icon="ShoppingBagIcon" class="text-white mr-1" size="20" />
                        Salaire sans CNSS
                    </template>
                    <hr />
                    <b-card no-body>
                        <b-card-header class="pb-50">
                            <h5>Filtrages</h5>
                        </b-card-header>
                        <b-card-body>
                            <b-row>
                                <b-col cols="12" md="12" lg="12" class="mb-md-0 mb-2">
                                    <b-row>
                                        <b-col cols="12" sm="6" md="3" lg="3">
                                            <label for="">Client</label>
                                            <v-select v-model="payloadFilter.user_id" :options="customerOptions"
                                                class="invoice-filter-select" label="name"
                                                :reduce="(customer) => customer.id">
                                                <template v-slot:no-options>
                                                    <b-spinner v-if="isCustomersWithoutPaginationLoading"
                                                        style="width: 2.5rem; height: 2.5rem"
                                                        class="align-middle text-info" />
                                                    <span v-else class="mt-1 font-medium-1">Aucun client ne
                                                        correspond</span>
                                                </template>
                                            </v-select>
                                        </b-col>

                                        <b-col cols="12" sm="6" md="3" lg="3">
                                            <label for="status">Mois</label>
                                            <v-select v-model="payloadFilter.month_salary" :options="monthOptions"
                                                label="text" class="invoice-filter-select" :reduce="(item) => item.value" />
                                        </b-col>
                                        <b-col cols="12" sm="6" md="3" lg="3">
                                            <label for="status">Année</label>
                                            <v-select v-model="payloadFilter.year" :options="yearOptions"
                                                class="invoice-filter-select w-100 mr-1" :reduce="(item) => item.value">
                                                <template #selected-option="{ label }">
                                                    <span class="text-truncate overflow-hidden">
                                                        {{ label }}
                                                    </span>
                                                </template>
                                            </v-select>
                                        </b-col>

                                        <b-col cols="12" sm="6" md="3" lg="3">
                                            <label for="status">Statut</label>
                                            <v-select v-model="payloadFilter.status" :options="paymentStatusOptions"
                                                class="invoice-filter-select" :reduce="(item) => item.value">
                                                <template #selected-option="{ label }">
                                                    <span class="text-truncate overflow-hidden">
                                                        {{ label }}
                                                    </span>
                                                </template>
                                            </v-select>
                                        </b-col>

                                        <b-col v-if="getUser.role.slug === 'super-administrateur' ||
                                            getUser.role.slug === 'responsable-relation-client'
                                            " cols="12" sm="6" md="3" lg="3">
                                            <label for="">Chargé d'affaire</label>
                                            <b-form-select v-if="businessManagerOptions.length === 1 &&
                                                businessManagerOptions[0].disabled
                                                " id="charge_daffaire_id" :options="businessManagerOptions" />
                                            <v-select v-else id="role" v-model="payloadFilter.ca_id"
                                                :reduce="(businessManager) => businessManager.id" label="full_name"
                                                :options="businessManagerOptions">
                                                <span slot="no-options" @click="$refs.select.open = false">
                                                    Chargement...
                                                </span>
                                            </v-select>
                                        </b-col>

                                        <b-col cols="12" sm="6" md="4" lg="3" class="mt-2">
                                            <b-button v-b-modal.modal-filtre-details-salary-without-cnss variant="primary"
                                                @click="applyFilterBusinessSalaryPaymentWithoutCnssAction(0)">
                                                <div v-if="isFilterDirectSalaryPayment">
                                                    <span> Chargement ... </span>
                                                    <b-spinner small />
                                                </div>
                                                <span v-else class="text-nowrap font-medium-1">Filtrer</span>
                                            </b-button>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>
                        </b-card-body>
                    </b-card>

                    <b-card title="Historique des paiements business">

                        <b-row class="invoice-preview">
                            <b-col cols="12" xl="9" md="8">
                                <vue-good-table :columns="columns" :rows="rowsWithoutCnss"
                                    :is-loading="isBusinessPaymentHistoryLoading">
                                    <template slot="emptystate">
                                        <div class="text-center">
                                            <span> {{ isBusinessPaymentHistoryLoading ? "" : "Liste vide" }} </span>
                                        </div>
                                    </template>

                                    <template slot="loadingContent">
                                        <div class="text-center text-info">
                                            <b-spinner class="align-middle" /> &nbsp;
                                            <span class="align-middle">Chargement... </span>
                                        </div>
                                    </template>

                                    <template slot="table-row" slot-scope="props">
                                        <div v-if="props.column.field === 'customer_full_name'"
                                            class="text-nowrap text-center align-middle">
                                            <span class="d-block text-nowrap text-info font-small-5">{{
                                                props.row.company_full_name
                                            }}</span>
                                            <!-- <span class="d-block text-nowrap text-warning font-small-5" v-if="props.row.is_company == true">IFU:
                                    <span class="d-block text-nowrap text-warning font-small-5">{{
                   props.row.company_ifu 
                  }}</span>
                                </span> -->
                                        </div>

                                        <div v-else-if="props.column.field === 'month_salary'"
                                            class="text-nowrap text-center align-middle">
                                            <span class="d-block text-nowrap text-info font-small-5">{{
                                                props.row.month_salary
                                            }}</span>
                                        </div>

                                        <div v-else-if="props.column.field === 'salary_paid_date'"
                                            class="text-nowrap text-center align-middle">
                                            <span class="d-block text-nowrap text-info font-small-5">{{
                                                props.row.salary_paid_date == null ? "---------" :
                                                moment(props.row.salary_paid_date).locale("fr").format("llll").split("00:00")[0]
                                            }}</span>
                                        </div>

                                        <div v-else-if="props.column.field === 'year'"
                                            class="text-nowrap text-center align-middle">
                                            <span class="d-block text-nowrap text-info font-small-5">{{
                                                props.row.year
                                            }}</span>
                                        </div>

                                        <div v-else-if="props.column.field === 'total_amount_to_be_paid'"
                                            class="text-nowrap text-center align-middle">
                                            <span class="d-block text-nowrap text-info font-small-5">{{
                                                props.row.total_amount_to_be_paid }} FCFA</span>
                                        </div>

                                        <div v-else-if="props.column.field === 'ylomi_direct_fees'"
                                            class="text-nowrap text-center align-middle">
                                            <span class="d-block text-nowrap text-info font-small-5">{{
                                                props.row.total_ylomi_direct_fees }} FCFA</span>
                                        </div>

                                        <div v-else-if="props.column.field === 'total_salary_advance_amount'"
                                            class="text-nowrap text-center align-middle">
                                            <span v-if="props.row.total_salary_advance_amount"
                                                class="d-block text-nowrap text-info font-small-5">{{
                                                    props.row.total_salary_advance_amount }} FCFA</span>
                                            <span v-else class="d-block text-nowrap text-info font-small-5">0 FCFA</span>

                                        </div>
                                        <div v-else-if="props.column.field === 'montantApayer'"
                                            class="text-nowrap text-center align-middle">
                                            <span v-if="montantApayer" class="d-block text-nowrap text-info font-small-5">{{
                                                montantApayer(props.row.total_amount_to_be_paid,
                                                    props.row.total_salary_advance_amount) }} FCFA</span>

                                        </div>

                                        <!-- Column: Status -->
                                        <div v-else-if="props.column.field === 'setStatus'"
                                            class="align-middle text-center font-small-5">
                                            <b-badge pill :variant="`light-${resolveOrderStatusVariant(props.row.status)}`"
                                                class="text-capitalize font-medium-1">
                                                {{ props.row.setStatus }}
                                            </b-badge>
                                        </div>

                                        <!--  ACTION -->
                                        <div v-else-if="props.column.field === 'action'">
                                            <span>
                                                <feather-icon v-b-modal.modal-action-without-cnss icon="MoreVerticalIcon"
                                                    class="text-info" size="20" @click="getInfosCumul(props.row)" />
                                            </span>
                                        </div>

                                    </template>

                                </vue-good-table>
                            </b-col>

                            <b-col cols="12" md="4" xl="3" class="invoice-actions">
                                <b-card>

                                    <b-button class="mb-75 font-weight-normal font-medium-1" block variant="primary"
                                        @click="reLoadDataPaymentHistoryBusinessOrdersAction()">
                                        <div v-if="isReLoadDataPaymentHistoryDirectOrders">
                                            <span> Chargement ... </span>
                                            <b-spinner small />
                                        </div>
                                        <span v-else class="text-nowrap font-medium-1">Actualiser</span>
                                    </b-button>
                                    <b-button
                                        v-if="getUser.role.slug == 'super-administrateur' || getUser.role.slug == 'comptable'"
                                        class="mb-75 font-weight-normal font-medium-1" block variant="warning"
                                        v-b-modal.modal-unpaid-salary-employee>
                                        <!--  <div v-if="isReLoadDataPaymentHistoryDirectOrders">
                                    <span> Chargement ... </span>
                                    <b-spinner small />
                                </div> -->
                                        <span class="text-nowrap font-medium-1">Marquer Salaire</span>
                                    </b-button>
                                    <b-button variant="outline-info" @click="showPayementBulk()"
                                        class="mb-75 font-weight-normal font-medium-1" block
                                        v-if="getUser.role.slug == 'super-administrateur' || getUser.role.slug === 'assistante-administrative' || getUser.role.slug == 'comptable'">
                                        <span class="text-nowrap font-medium-1">Fichier de paiement</span>
                                    </b-button>



                                </b-card>
                            </b-col>
                        </b-row>


                        <!-- MODAL ACTIONS SANS CNSS -->
                        <b-modal id="modal-action-without-cnss" cancel-variant="outline-secondary" hide-footer centered
                            no-close-on-backdrop no-close-on-esc title="Actions sur le paiement" size="sm">
                            <b-list-group>
                                <b-list-group-item button v-b-modal.modal-details-salary-without-cnss
                                    @click="showSalaireWithoutCnssDetails(salaryInfos, 0)">
                                    <feather-icon icon="EyeIcon" class="text-info" size="20" />
                                    <span class="align-middle ml-50">Voir détails</span>
                                </b-list-group-item>

                                <!-- <b-list-group-item button v-if="getUser.role.slug === 'super-administrateur' && salaryInfos.status == 0 || getUser.role.slug === 'comptable' && salaryInfos.status == 0" @click="applyConfirmSalaryCumulWithoutCnssAction(salaryInfos, 0)">
                                <feather-icon icon="CheckIcon" class="text-success" size="20" />
                                <span class="align-middle ml-50">Confirmer le paiement </span>
                            </b-list-group-item> -->

                                <!-- <b-list-group-item button v-b-modal.modal-generate-link v-if="getUser.role.slug === 'responsable-relation-client' && salaryInfos.status == 0 || getUser.role.slug === 'super-administrateur' && salaryInfos.status == 0" @click="clikedWithoutCnss(salaryInfos, 0)">
                                <feather-icon icon="LinkIcon" class="text-success" size="20" />
                                <span class="align-middle ml-50">Générer lien de payement au client</span>
                            </b-list-group-item>

                            <b-list-group-item button v-if="getUser.role.slug === 'super-administrateur' && salaryInfos.status == 0 " @click="applyCancelCumulPaymentWithoutCnssAction(salaryInfos, 0)">
                                <feather-icon icon="TrashIcon" class="text-danger" size="20" />
                                <span class="align-middle ml-50">Annuler le paiement</span>
                            </b-list-group-item> -->
                            </b-list-group>

                        </b-modal>

                        <!-- DETAILS D'UN PAIEMENT DE SALAIRE SANS CNSSS-->
                        <b-modal id="modal-details-salary-without-cnss" cancel-variant="outline-secondary" hide-footer
                            centered no-close-on-backdrop no-close-on-esc title="DETAILS D'UN PAYEMENT SINGULIER SANS CNSS"
                            size="xl" stacking>
                            <b-table ref="refInvoiceListTable" :items="salaryDetails" responsive :fields="tableColumns2"
                                primary-key="id" :busy="isPaymentSalaryDetails" show-empty empty-text="Liste vide"
                                class="position-relative">
                                <template #table-busy>
                                    <div class="text-center text-info my-2">
                                        <b-spinner class="align-middle" /> &nbsp;
                                        <strong>Chargement...</strong>
                                    </div>
                                </template>
                                <!-- Column: Invoice Status -->
                                <template #cell(status)="data">
                                    <b-badge pill :variant="`light-${resolveDetailSlaryWithoutCnssStatusVariant(
                                        data.item.status
                                    )}`" class="text-capitalize font-small-3">
                                        {{ data.item.status == true ? "Payé" : "Non payé" }}
                                    </b-badge>
                                </template>
                                <!-- Salaire -->
                                <template #cell(employee_salary_amount)="data">
                                    <span class="text-info">
                                        {{ data.item.employee_salary_amount }} FCFA
                                    </span>
                                </template>
                                <!-- Salaire -->
                                <template #cell(assurance_amount)="data">
                                    <span class="text-info">
                                        {{ data.item.assurance_amount }} FCFA
                                    </span>
                                </template>

                                <!-- Service  -->
                                <template #cell(service_name)="data">
                                    <span class="text-info">
                                        {{ data.item.business_recurring_order.recurring_service.name }}
                                    </span>
                                </template>

                                <template #cell(month_salary)="data">
                                    <span class="text-info">
                                        {{ data.item.month_salary }}
                                    </span>
                                </template>

                                <!-- Column: Customer -->
                                <template #cell(customer_full_name)="data">
                                    <template>
                                        <span class="font-weight-bold d-block text-nowrap text-info">
                                            {{ clickedDetailCustomerName }}
                                        </span>
                                    </template>
                                    <span class="font-weight-bold d-block text-nowrap text-success">
                                        {{ clickedDetailIfu }}
                                    </span>
                                </template>

                                <!-- Column: employee -->
                                <template #cell(employee_full_name)="data">
                                    <b-media vertical-align="center">
                                        <template #aside>
                                            <b-avatar size="32" :src="data.item.employee.profile_image" />
                                        </template>
                                        <span class="font-weight-bold d-block text-nowrap text-info">
                                            {{ data.item.employee.full_name }}
                                        </span>
                                    </b-media>
                                </template>
                                <!-- AVANCE SUR SALAIRE -->
                                <template #cell(salary_advance_amount)="data">
                                    <span class="text-info">
                                        {{ data.item.salary_advance_amount == null ? 0 : data.item.salary_advance_amount }}
                                        FCFA
                                    </span>
                                </template>
                                <!-- SALAIRE NET A PRENDRE  -->
                                <template #cell(net_salary)="data">
                                    <span class="text-info">
                                        {{ net_salary(data.item.employee_salary_amount, data.item.salary_advance_amount) }}
                                        FCFA
                                    </span>
                                </template>
                                <!-- SALAIRE NET A PAYER -->
                                <template #cell(netApayer)="data">
                                    <span class="text-info">
                                        {{ netApayer(data.item.total_amount_to_paid, data.item.salary_advance_amount) }}
                                        FCFA
                                    </span>
                                </template>

                                <template #cell(ylomi_direct_fees)="data">
                                    <span class="text-info"> {{ data.item.ylomi_direct_fees }} FCFA </span>
                                </template>
                                <template #cell(total_amount_to_paid)="data">
                                    <span class="text-info">
                                        {{ data.item.total_amount_to_paid }} FCFA
                                    </span>
                                </template>

                                <template #cell(salary_paid_date)="data">
                                    <span class="text-info">{{
                                        data.item.salary_paid_date == null ? "---------" :
                                        moment(data.item.salary_paid_date).locale("fr").format("llll").split("00:00")[0]
                                    }}</span>
                                </template>

                                <!-- Column: Actions SINGULAR WITHOUT CNSS -->
                                <template #cell(actions)="data" v-if="getUser.role.slug === 'super-administrateur'
                                        || getUser.role.slug === 'assistante-administrative'
                                        || getUser.role.slug === 'comptable'
                                        || getUser.role.slug === 'responsable-relation-client'
                                        || getUser.role.slug === 'charge-daffaires'">

                                    <feather-icon v-b-modal.modal-action-singular-without-cnss icon="MoreVerticalIcon"
                                        class="text-info" size="20" @click="getInfosSingulier(data.item)" />

                                    <!-- Terminate Employee Contract Modal Résilier-->
                                </template>
                            </b-table>
                            <br />
                            <hr />
                            <div>
                                <h4 class="mb-2 text-center">TOTAL PAIEMENT</h4>
                                <b-row>
                                    <b-col>
                                        <span class="text-md  font-weight-bold">Total salaire net des employés</span>
                                    </b-col>
                                    <b-col>
                                        <span class="text-info"> {{ totalSalaireNet }} FCFA</span>
                                    </b-col>
                                </b-row>
                                <br />

                                <b-row>
                                    <b-col>
                                        <span class="text-md  font-weight-bold">Total assurance des employés</span>
                                    </b-col>
                                    <b-col>
                                        <span class="text-info">{{ totalAssuranceEmployeeSalary }} FCFA</span>
                                    </b-col>
                                </b-row>
                                <br />
                                <b-row>
                                    <b-col>
                                        <span class="text-md  font-weight-bold">Total Comission Ylomi</span>
                                    </b-col>
                                    <b-col>
                                        <span class="text-info">{{ totalYlomiFeesSalary }} FCFA</span>
                                    </b-col>
                                </b-row>
                                <br />
                                <b-row>
                                    <b-col>
                                        <span class="text-md  font-weight-bold"> Total Avance sur salaire</span>
                                    </b-col>
                                    <b-col>
                                        <span class="text-info">{{ totalAdvanceSalary }} FCFA</span>
                                    </b-col>
                                </b-row>
                                <br />

                                <b-row>
                                    <b-col>
                                        <span class="text-md  font-weight-bold"> Total slaire net Après Avance sur
                                            salaire</span>
                                    </b-col>
                                    <b-col>
                                        <span class="text-info">{{ totalNetAfterAdvanceSalary }} FCFA</span>
                                    </b-col>
                                </b-row>
                                <br />
                                <hr />
                                <div class="justify-content-center">
                                    <b-row>
                                        <b-col>
                                            <span class="text-md  font-weight-bold">Total NET A PAYER</span>
                                        </b-col>
                                        <b-col>
                                            <span class="text-info">{{ totalAmountSalary }} FCFA</span>
                                        </b-col>
                                    </b-row>
                                </div>
                            </div>
                            <!-- MODAL ACTIONS SANS CNSS -->
                            <b-modal id="modal-action-singular-without-cnss" cancel-variant="outline-secondary" hide-footer
                                centered no-close-on-backdrop no-close-on-esc title="Actions sur le paiement" size="sm">
                                <b-list-group>
                                    <!-- <b-list-group-item button :disable="salarySingularInfos.status == true" v-if="getUser.role.slug === 'super-administrateur' && salarySingularInfos.status == 0 || getUser.role.slug === 'comptable' && salaryInfos.status == 0" v-b-modal.modal-details-salary-without-cnss @click="confirmSingularSalaryPaymentWithoutCnss(salarySingularInfos, 0)">
                                    <feather-icon icon="CheckIcon" class="text-success" size="20" />
                                    <span class="align-middle ml-50">Confirmer le Paiement</span>
                                </b-list-group-item> -->
                                    <b-list-group-item
                                        v-if="getUser.role.slug === 'super-administrateur' || getUser.role.slug === 'assistante-administrative' || getUser.role.slug === 'comptable'"
                                        button @click="employeeWallet(salarySingularInfos, 0)">
                                        <feather-icon icon="PocketIcon" class="text-info" size="20" />
                                        <span>Portefeuille employé</span>
                                    </b-list-group-item>

                                    <b-list-group-item button
                                        v-if="(getUser.role.slug === 'super-administrateur' ||
                                            getUser.role.slug === 'charge-daffaires' ||
                                            getUser.role.slug === 'responsable-relation-client' || getUser.role.slug === 'assistante-administrative' || getUser.role.slug === 'comptable') && salarySingularInfos.status == 0"
                                        @click="showAdvanceSingularPayementMonthModalWithoutCnss(salarySingularInfos)">
                                        <feather-icon icon="CreditCardIcon" class="text-info" size="20" />
                                        <span class="align-middle ml-50">Avance sur salaire</span>
                                    </b-list-group-item>

                                    <b-list-group-item button v-if="getUser.role.slug === 'super-administrateur'"
                                        @click="cancelPaymentSansCnss(salarySingularInfos, 0)">
                                        <feather-icon icon="TrashIcon" class="text-danger" size="20" />
                                        <span class="align-middle ml-50">Annuler le paiement </span>
                                    </b-list-group-item>

                                    <!-- <b-list-group-item button v-b-modal.modal-generate-link @click="clikedSigular(salarySingularInfos)">
                                    <feather-icon icon="LinkIcon" class="text-success" size="20" />
                                    <span class="align-middle ml-50">Générer lien de payement au client</span>
                                </b-list-group-item>

                                <b-list-group-item button v-if="getUser.role.slug === 'super-administrateur' " @click="showAdvanceSingularPayementMonthModalWithoutCnss(salarySingularInfos)">
                                    <feather-icon icon="DownloadIcon" class="text-info" size="20" />
                                    <span class="align-middle ml-50">Télécharger le fichier de paiement</span>
                                </b-list-group-item> -->


                                </b-list-group>

                            </b-modal>
                        </b-modal>
                        <div class="d-flex justify-content-between mt-2 flex-wrap">
                            <b-button :disabled="metaDataWithoutCnss.previous_page_url == null" variant="outline-secondary"
                                @click="
                                    applyLoadMoreBusinessOrderPaymentHistoryAction(metaDataWithoutCnss.prev_page_url)
                                    ">
                                <span class="text-white text-nowrap font-medium-2">Page précédente</span>
                            </b-button>

                            <div>
                                <span class="text-white font-medium-2">{{ metaDataWithoutCnss.current_page }} sur {{
                                    metaDataWithoutCnss.last_page }} pages</span>
                            </div>
                            <div>
                                <span class="text-white font-medium-2">Total :{{ totalWithoutCnss }}</span>
                            </div>
                            <b-button :disabled="metaDataWithoutCnss.next_page_url == null" variant="outline-secondary"
                                @click="
                                    applyLoadMoreBusinessOrderPaymentHistoryAction(metaDataWithoutCnss.next_page_url)
                                    ">
                                <span class="text-nowrap font-medium-1 text-white">Page suivante</span>
                            </b-button>
                        </div>
                        <!-- MODAL DE DETAIL DE L'HISTORIQUE DE PAYEMENT DU CLIENT -->
                    </b-card>
                </b-tab>
                <b-tab>
                    <template #title>
                        <feather-icon icon="ShoppingBagIcon" class="text-white mr-1" size="20" />
                        Salaire avec CNSS
                    </template>
                    <hr />
                    <b-card no-body>
                        <b-card-header class="pb-50">
                            <h5>Filtrages</h5>
                        </b-card-header>
                        <b-card-body>
                            <b-row>
                                <b-col cols="12" md="12" lg="12" class="mb-md-0 mb-2">
                                    <b-row>
                                        <b-col cols="12" sm="6" md="3" lg="3">
                                            <label for="">Client</label>
                                            <v-select v-model="payloadFilter.user_id" :options="customerOptions"
                                                class="invoice-filter-select" label="name"
                                                :reduce="(customer) => customer.id">
                                                <template v-slot:no-options>
                                                    <b-spinner v-if="isCustomersWithoutPaginationLoading"
                                                        style="width: 2.5rem; height: 2.5rem"
                                                        class="align-middle text-info" />
                                                    <span v-else class="mt-1 font-medium-1">Aucun client ne
                                                        correspond</span>
                                                </template>
                                            </v-select>
                                        </b-col>

                                        <b-col cols="12" sm="6" md="3" lg="3">
                                            <label for="status">Mois</label>
                                            <v-select v-model="payloadFilter.month_salary" :options="monthOptions"
                                                label="text" class="invoice-filter-select" :reduce="(item) => item.value" />
                                        </b-col>
                                        <b-col cols="12" sm="6" md="3" lg="3">
                                            <label for="status">Année</label>
                                            <v-select v-model="payloadFilter.year" :options="yearOptions"
                                                class="invoice-filter-select w-100 mr-1" :reduce="(item) => item.value">
                                                <template #selected-option="{ label }">
                                                    <span class="text-truncate overflow-hidden">
                                                        {{ label }}
                                                    </span>
                                                </template>
                                            </v-select>
                                        </b-col>
                                        <b-col cols="12" sm="6" md="3" lg="3">
                                            <label for="status">Statut</label>
                                            <v-select v-model="payloadFilter.status" :options="paymentStatusOptions"
                                                class="invoice-filter-select" :reduce="(item) => item.value">
                                                <template #selected-option="{ label }">
                                                    <span class="text-truncate overflow-hidden">
                                                        {{ label }}
                                                    </span>
                                                </template>
                                            </v-select>
                                        </b-col>


                                        <b-col v-if="getUser.role.slug === 'super-administrateur' ||
                                            getUser.role.slug === 'super-administrateur' ||
                                            getUser.role.slug === 'responsable-relation-client'
                                            " cols="12" sm="6" md="3" lg="3">
                                            <label for="">Chargé d'affaire</label>
                                            <b-form-select v-if="businessManagerOptions.length === 1 &&
                                                businessManagerOptions[0].disabled
                                                " id="charge_daffaire_id" :options="businessManagerOptions" />
                                            <v-select v-else id="role" v-model="payloadFilter.ca_id"
                                                :reduce="(businessManager) => businessManager.id" label="full_name"
                                                :options="businessManagerOptions">
                                                <span slot="no-options" @click="$refs.select.open = false">
                                                    Chargement...
                                                </span>
                                            </v-select>
                                        </b-col>
                                        <b-col cols="12" sm="6" md="4" lg="3" class="mt-2">
                                            <b-button v-b-modal.modal-filtre-details-salary variant="primary"
                                                @click="applyFilterBusinessSalaryPaymentAction(1)">
                                                <div v-if="isFilterDirectSalaryPayment">
                                                    <span> Chargement ... </span>
                                                    <b-spinner small />
                                                </div>
                                                <span v-else class="text-nowrap font-medium-1">Filtrer</span>
                                            </b-button>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>
                            <b-row class="mt-4">
                                <b-col cols="12" md="10" lg="10" sm="6">
                                    <label for="service">Date de Transaction</label>
                                    <b-form-group>
                                        <flat-pickr v-model="rangeDate" class="form-control"
                                            placeholder="Sélectionner une intervalle de date" :config="config" />
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12" sm="6" md="2" lg="2" class="mt-2">
                                    <b-button v-b-modal.modal-filtre-cnss variant="primary"
                                        @click="applyFilterCnssAction()">
                                        <div v-if="isFilterCnss">
                                            <span> Chargement ... </span>
                                            <b-spinner small />
                                        </div>
                                        <span v-else class="text-nowrap font-medium-1">Filtrer</span>
                                    </b-button>
                                </b-col>
                            </b-row>
                        </b-card-body>
                    </b-card>

                    <b-card title="Historique des salaires">

                        <b-row class="invoice-preview">
                            <b-col cols="12" xl="9" md="8">
                                <vue-good-table :columns="columns" :rows="rowsWithCnss"
                                    :is-loading="isBusinessPaymentHistoryLoading">
                                    <template slot="emptystate">
                                        <div class="text-center">
                                            <span> {{ isBusinessPaymentHistoryLoading ? "" : "Liste vide" }} </span>
                                        </div>
                                    </template>

                                    <template slot="loadingContent">
                                        <div class="text-center text-info">
                                            <b-spinner class="align-middle" /> &nbsp;
                                            <span class="align-middle">Chargement... </span>
                                        </div>
                                    </template>

                                    <template slot="table-row" slot-scope="props">
                                        <div v-if="props.column.field === 'customer_full_name'"
                                            class="text-nowrap text-center align-middle">
                                            <span class="d-block text-nowrap text-info font-small-5">{{
                                                props.row.company_full_name
                                            }}</span>

                                            <!-- <span class="d-block text-nowrap text-warning font-small-5" v-if="props.row.is_company == true && props.row.company_ifu != 'null'">IFU:
                                    <span class="d-block text-nowrap text-warning font-small-5">{{
                   props.row.company_ifu 
                  }}</span>

                                </span> -->
                                        </div>

                                        <div v-else-if="props.column.field === 'month_salary'"
                                            class="text-nowrap text-center align-middle">
                                            <span class="d-block text-nowrap text-info font-small-5">{{
                                                props.row.month_salary
                                            }}</span>
                                        </div>

                                        <div v-else-if="props.column.field === 'salary_paid_date'"
                                            class="text-nowrap text-center align-middle">
                                            <span class="d-block text-nowrap text-info font-small-5">{{
                                                props.row.salary_paid_date == null ? "---------" :
                                                moment(props.row.salary_paid_date).locale("fr").format("llll").split("00:00")[0]
                                            }}</span>
                                        </div>

                                        <div v-else-if="props.column.field === 'year'"
                                            class="text-nowrap text-center align-middle">
                                            <span class="d-block text-nowrap text-info font-small-5">{{
                                                props.row.year
                                            }}</span>
                                        </div>

                                        <div v-else-if="props.column.field === 'total_amount_to_be_paid'"
                                            class="text-nowrap text-center align-middle">
                                            <span class="d-block text-nowrap text-info font-small-5">{{
                                                props.row.total_amount_to_be_paid }} FCFA</span>
                                        </div>

                                        <div v-else-if="props.column.field === 'ylomi_direct_fees'"
                                            class="text-nowrap text-center align-middle">
                                            <span class="d-block text-nowrap text-info font-small-5">{{
                                                props.row.total_ylomi_direct_fees }} FCFA</span>
                                        </div>

                                        <div v-else-if="props.column.field === 'total_salary_advance_amount'"
                                            class="text-nowrap text-center align-middle">
                                            <span v-if="props.row.total_salary_advance_amount"
                                                class="d-block text-nowrap text-info font-small-5">{{
                                                    props.row.total_salary_advance_amount }} FCFA</span>
                                            <span v-else class="d-block text-nowrap text-info font-small-5">0 FCFA</span>

                                        </div>
                                        <div v-else-if="props.column.field === 'montantApayer'"
                                            class="text-nowrap text-center align-middle">
                                            <span v-if="montantApayer" class="d-block text-nowrap text-info font-small-5">{{
                                                montantApayer(props.row.total_amount_to_be_paid,
                                                    props.row.total_salary_advance_amount) }} FCFA</span>

                                        </div>

                                        <!-- Column: Status -->
                                        <div v-else-if="props.column.field === 'setStatus'"
                                            class="align-middle text-center font-small-5">
                                            <b-badge pill :variant="`light-${resolveOrderStatusVariant(props.row.status)}`"
                                                class="text-capitalize font-medium-1">
                                                {{ props.row.setStatus }}
                                            </b-badge>
                                        </div>

                                        <!--  ACTION -->
                                        <div v-else-if="props.column.field === 'action'">
                                            <span>
                                                <feather-icon v-b-modal.modal-action-with-cnss icon="MoreVerticalIcon"
                                                    class="text-info" size="20" @click="getInfosCumul(props.row)" />
                                            </span>
                                        </div>

                                    </template>

                                </vue-good-table>
                            </b-col>

                            <b-col cols="12" md="4" xl="3" class="invoice-actions">
                                <b-card>

                                    <b-button class="mb-75 font-weight-normal font-medium-1" block variant="primary"
                                        @click="reLoadDataPaymentHistoryBusinessOrdersAction()">
                                        <div v-if="isReLoadDataPaymentHistoryDirectOrders">
                                            <span> Chargement ... </span>
                                            <b-spinner small />
                                        </div>
                                        <span v-else class="text-nowrap font-medium-1">Actualiser</span>
                                    </b-button>
                                    <b-button
                                        v-if="getUser.role.slug == 'super-administrateur' || getUser.role.slug == 'comptable'"
                                        class="mb-75 font-weight-normal font-medium-1" block variant="warning"
                                        v-b-modal.modal-unpaid-salary-employee>
                                        <!--  <div v-if="isReLoadDataPaymentHistoryDirectOrders">
                                    <span> Chargement ... </span>
                                    <b-spinner small />
                                </div> -->
                                        <span class="text-nowrap font-medium-1">Marquer Salaire</span>
                                    </b-button>
                                    <b-button variant="outline-info" @click="showPayementBulk()"
                                        class="mb-75 font-weight-normal font-medium-1" block
                                        v-if="getUser.role.slug == 'super-administrateur' || getUser.role.slug === 'assistante-administrative' || getUser.role.slug == 'comptable'">
                                        <span class="text-nowrap font-medium-1">Fichier de paiement</span>
                                    </b-button>
                                    <b-button class="mb-75 font-weight-normal font-medium-1" block
                                        v-if="getUser.role.slug == 'super-administrateur' || getUser.role.slug === 'assistante-administrative' || getUser.role.slug == 'comptable'"
                                        variant="success" v-b-modal.apply-cnss-modal>
                                        <div v-if="isReLoadDataPaymentHistoryDirectOrders">
                                            <span> Chargement ... </span>
                                            <b-spinner small />
                                        </div>
                                        <span v-else class="text-nowrap font-medium-1">Déclencher Cnss</span>
                                    </b-button>
                                </b-card>
                            </b-col>
                        </b-row>


                        <!-- MODAL ACTIONS SANS CNSS -->
                        <b-modal id="modal-action-with-cnss" cancel-variant="outline-secondary" hide-footer centered
                            no-close-on-backdrop no-close-on-esc title="Actions sur le paiement" size="sm">
                            <b-list-group>
                                <b-list-group-item button v-b-modal.modal-details-salary
                                    @click="showSalaireDetails(salaryInfos, 1)">
                                    <feather-icon icon="EyeIcon" class="text-info" size="20" />
                                    <span class="align-middle ml-50">Voir détails</span>
                                </b-list-group-item>

                                <!-- <b-list-group-item button v-if="getUser.role.slug === 'super-administrateur' && salaryInfos.status == 0 || getUser.role.slug === 'comptable' && salaryInfos.status == 0 " @click="applyConfirmSalaryCumulAction(salaryInfos, 1)">
                                <feather-icon icon="CheckIcon" class="text-success" size="20" />
                                <span class="align-middle ml-50">Confirmer le paiement </span>
                            </b-list-group-item> -->

                                <!-- <b-list-group-item button v-b-modal.modal-generate-link v-if="getUser.role.slug === 'responsable-relation-client' && salaryInfos.status == 0 || getUser.role.slug === 'super-administrateur' && salaryInfos.status == 0" @click="clikedWithCnss(salaryInfos, 1)">
                                <feather-icon icon="LinkIcon" class="text-success" size="20" />
                                <span class="align-middle ml-50">Générer lien de payement au client</span>
                            </b-list-group-item>

                            <b-list-group-item button v-if="getUser.role.slug === 'super-administrateur' && salaryInfos.status == 0 " @click="applyCancelCumulPaymentAction(salaryInfos, 1)">
                                <feather-icon icon="TrashIcon" class="text-danger" size="20" />
                                <span class="align-middle ml-50">Annuler le paiement</span>
                            </b-list-group-item> -->
                            </b-list-group>

                        </b-modal>

                        <!-- DETAILS D'UN PAIEMENT DE SALAIRE AVEC CNSS -->
                        <b-modal id="modal-details-salary" cancel-variant="outline-secondary" hide-footer centered
                            no-close-on-backdrop no-close-on-esc title="DETAILS D'UN PAYEMENT SINGULIER AVEC CNSS" size="xl"
                            stacking>
                            <b-table ref="refInvoiceListTable" :items="salaryDetails" responsive :fields="tableColumns1"
                                primary-key="id" :busy="isPaymentSalaryDetails" show-empty empty-text="Liste vide"
                                class="position-relative">
                                <template #table-busy>
                                    <div class="text-center text-info my-2">
                                        <b-spinner class="align-middle" /> &nbsp;
                                        <strong>Chargement...</strong>
                                    </div>
                                </template>
                                <!-- Column: Invoice Status -->
                                <template #cell(status)="data">
                                    <b-badge pill :variant="`light-${resolveDetailSlaryStatusVariant(
                                        data.item.status
                                    )}`" class="text-capitalize font-small-3">
                                        {{ data.item.status == true ? "Payé" : "Non payé" }}
                                    </b-badge>
                                </template>
                                <!-- Salaire -->
                                <template #cell(employee_salary_amount)="data">
                                    <span class="text-info">
                                        {{ data.item.employee_salary_amount }} FCFA
                                    </span>
                                </template>

                                <!-- Salaire -->
                                <template #cell(service_name)="data">
                                    <span class="text-info">
                                        {{ data.item.business_recurring_order.recurring_service.name }}
                                    </span>
                                </template>

                                <!-- Column: Customer -->
                                <template #cell(customer_full_name)="data">
                                    <template>
                                        <span class="font-weight-bold d-block text-nowrap text-info">
                                            {{ clickedDetailCustomerName }}
                                        </span>
                                    </template>
                                    <span class="font-weight-bold d-block text-nowrap text-success">
                                        {{ clickedDetailIfu }}
                                    </span>
                                </template>
                                <!-- Column: employee -->
                                <template #cell(employee_full_name)="data">
                                    <b-media vertical-align="center">
                                        <template #aside>
                                            <b-avatar size="32" :src="data.item.employee.profile_image" />
                                        </template>
                                        <span class="font-weight-bold d-block text-nowrap text-info">
                                            {{ data.item.employee.full_name }}
                                        </span>
                                    </b-media>
                                </template>
                                <!-- Column: Employee contract started date -->
                                <template #cell(cnss_customer_amount)="data">
                                    <span class="text-info">
                                        {{ data.item.cnss_customer_amount }} FCFA
                                    </span>
                                </template>

                                <template #cell(cnss_employee_amount)="data">
                                    <span class="text-info">
                                        {{ data.item.cnss_employee_amount }} FCFA
                                    </span>
                                </template>

                                <template #cell(its_amount)="data">
                                    <span class="text-info"> {{ data.item.its_amount }} FCFA </span>
                                </template>
                                <template #cell(vps_amount)="data">
                                    <span class="text-info"> {{ data.item.vps_amount }} FCFA </span>
                                </template>
                                <template #cell(ylomi_direct_fees)="data">
                                    <span class="text-info"> {{ data.item.ylomi_direct_fees }} FCFA </span>
                                </template>
                                <!-- AVANCE SUR SALAIRE -->
                                <template #cell(salary_advance_amount)="data">
                                    <span class="text-info">
                                        {{ data.item.salary_advance_amount == null ? 0 : data.item.salary_advance_amount }}
                                        FCFA
                                    </span>
                                </template>
                                <!-- SALAIRE NET A PRENDRE  -->
                                <template #cell(net_salary)="data">
                                    <span class="text-info">
                                        {{ net_salary(data.item.employee_salary_amount, data.item.salary_advance_amount) }}
                                        FCFA
                                    </span>
                                </template>
                                <!-- SALAIRE NET A PAYER -->
                                <template #cell(netApayer)="data">
                                    <span class="text-info">
                                        {{ netApayer(data.item.total_amount_to_paid, data.item.salary_advance_amount) }}
                                        FCFA
                                    </span>
                                </template>
                                <template #cell(total_amount_to_paid)="data">
                                    <span class="text-info">
                                        {{ data.item.total_amount_to_paid }} FCFA
                                    </span>
                                </template>

                                <template #cell(month_salary)="data">
                                    <span class="text-info">
                                        {{ data.item.month_salary }}
                                    </span>
                                </template>

                                <template #cell(salary_paid_date)="data">
                                    <span class="text-info">{{
                                        data.item.salary_paid_date == null ? "---------" :
                                        moment(data.item.salary_paid_date).locale("fr").format("llll").split("00:00")[0]
                                    }}</span>
                                </template>

                                <!-- Column: Actions SINGULAR WITHOUT CNSS -->
                                <template #cell(actions)="data"
                                    v-if="getUser.role.slug === 'super-administrateur' || getUser.role.slug === 'assistante-administrative' || getUser.role.slug === 'comptable'">

                                    <feather-icon v-b-modal.modal-action-singular-with-cnss icon="MoreVerticalIcon"
                                        class="text-info" size="20" @click="getInfosSingulier(data.item)" />

                                    <!-- Terminate Employee Contract Modal Résilier-->
                                </template>
                            </b-table>

                            <br />
                            <hr />
                            <div>
                                <h4 class="mb-2 text-center">TOTAL PAIEMENT</h4>
                                <b-row>
                                    <b-col>
                                        <span class="text-md  font-weight-bold">Total salaire net des employés</span>
                                    </b-col>
                                    <b-col>
                                        <span class="text-info"> {{ totalSalaireNet }} FCFA</span>
                                    </b-col>
                                </b-row>
                                <br />
                                <b-row>
                                    <b-col>
                                        <span class="text-md  font-weight-bold">Total cnss part patronnal</span>
                                    </b-col>
                                    <b-col>
                                        <span class="text-info"> {{ totalCnssCustomerSalary }} FCFA</span>
                                    </b-col>
                                </b-row>
                                <br />
                                <b-row>
                                    <b-col>
                                        <span class="text-md  font-weight-bold">Total cnss part employé</span>
                                    </b-col>
                                    <b-col>
                                        <span class="text-info">{{ totalCnssEmployeeSalary }} FCFA</span>
                                    </b-col>
                                </b-row>
                                <br />
                                <b-row>
                                    <b-col>
                                        <span class="text-md  font-weight-bold">Total VPS</span>
                                    </b-col>

                                    <b-col>
                                        <span class="text-info">{{ totalVpsSalary }} FCFA</span>
                                    </b-col>
                                </b-row>
                                <br />
                                <b-row>
                                    <b-col>
                                        <span class="text-md  font-weight-bold">Total ITS</span>
                                    </b-col>
                                    <b-col>
                                        <span class="text-info">{{ totalItsSalary }} FCFA</span>
                                    </b-col>
                                </b-row>
                                <br />
                                <b-row>
                                    <b-col>
                                        <span class="text-md  font-weight-bold">Total Comission Ylomi</span>
                                    </b-col>
                                    <b-col>
                                        <span class="text-info">{{ totalYlomiFeesSalary }} FCFA</span>
                                    </b-col>
                                </b-row>
                                <br />
                                <b-row>
                                    <b-col>
                                        <span class="text-md  font-weight-bold"> Total Avance sur salaire</span>
                                    </b-col>
                                    <b-col>
                                        <span class="text-info">{{ totalAdvanceSalary }} FCFA</span>
                                    </b-col>
                                </b-row>
                                <br />

                                <b-row>
                                    <b-col>
                                        <span class="text-md  font-weight-bold"> Total slaire net Après Avance sur
                                            salaire</span>
                                    </b-col>
                                    <b-col>
                                        <span class="text-info">{{ totalNetAfterAdvanceSalary }} FCFA</span>
                                    </b-col>
                                </b-row>
                                <br />
                                <hr />
                                <div class="justify-content-center">
                                    <b-row>
                                        <b-col>
                                            <span class="text-md  font-weight-bold">Total NET A PAYER</span>
                                        </b-col>
                                        <b-col>
                                            <span class="text-info">{{ totalAmountSalary }} FCFA</span>
                                        </b-col>
                                    </b-row>
                                </div>
                            </div>
                            <!-- MODAL ACTIONS AVEC CNSS -->
                            <b-modal id="modal-action-singular-with-cnss" cancel-variant="outline-secondary" hide-footer
                                centered no-close-on-backdrop no-close-on-esc title="Actions sur le paiement" size="sm">
                                <b-list-group>
                                    <!-- <b-list-group-item button :disable="salarySingularInfos.status == true" v-if="getUser.role.slug === 'super-administrateur'&& salarySingularInfos.status == 0 || getUser.role.slug === 'comptable' && salaryInfos.status == 0" v-b-modal.modal-details-salary-without-cnss @click="confirmSingularSalaryPayment(salarySingularInfos, 1)">
                                    <feather-icon icon="CheckIcon" class="text-success" size="20" />
                                    <span class="align-middle ml-50">Confirmer le Paiement</span>
                                </b-list-group-item> -->
                                    <b-list-group-item
                                        v-if="getUser.role.slug === 'super-administrateur' || getUser.role.slug === 'assistante-administrative' || getUser.role.slug === 'comptable'"
                                        button @click="employeeWallet(salarySingularInfos, 1)">
                                        <feather-icon icon="PocketIcon" class="text-info" size="20" />
                                        <span>Portefeuille employé</span>
                                    </b-list-group-item>

                                    <b-list-group-item button
                                        v-if="(getUser.role.slug === 'super-administrateur' ||
                                            getUser.role.slug === 'charge-daffaires' ||
                                            getUser.role.slug === 'responsable-relation-client' || getUser.role.slug === 'assistante-administrative' || getUser.role.slug === 'comptable') && salarySingularInfos.status == 0"
                                        @click="showAdvanceSingularPayementMonthModal(salarySingularInfos)">
                                        <feather-icon icon="CreditCardIcon" class="text-info" size="20" />
                                        <span class="align-middle ml-50">Avance sur salaire</span>
                                    </b-list-group-item>

                                    <b-list-group-item button v-if="getUser.role.slug === 'super-administrateur'"
                                        @click="cancelPayment(salarySingularInfos, 1)">
                                        <feather-icon icon="TrashIcon" class="text-danger" size="20" />
                                        <span class="align-middle ml-50">Annuler le paiement </span>
                                    </b-list-group-item>

                                    <!--<b-list-group-item button v-b-modal.modal-generate-link @click="clikedSigular(salarySingularInfos)">
                                    <feather-icon icon="LinkIcon" class="text-success" size="20" />
                                    <span class="align-middle ml-50">Générer lien de payement au client</span>
                                </b-list-group-item>

                                <b-list-group-item button v-if="getUser.role.slug === 'super-administrateur' " @click="showAdvanceSingularPayementMonthModal(salarySingularInfos)">
                                    <feather-icon icon="CreditCardIcon" class="text-info" size="20" />
                                    <span class="align-middle ml-50">Avance sur salaire</span>
                                </b-list-group-item>

                                <b-list-group-item button v-if="getUser.role.slug === 'super-administrateur' " @click="blockSingularSalaryPayment(salarySingularInfos, 1)">
                                    <feather-icon icon="ShuffleIcon" class="text-danger" size="20" />
                                    <span v-if="salarySingularInfos.auto_send == true"> Bloqué le salaire</span>
                                    <span v-else>Débloqué le salaire</span>
                                </b-list-group-item> -->
                                </b-list-group>

                            </b-modal>

                        </b-modal>

                        <!--MODAL FILTRE DES DETAILS D'UN PAIEMENT DE SALAIRE AVEC CNSS -->
                        <b-modal id="modal-filtre-details-salary" cancel-variant="outline-secondary" hide-footer centered
                            no-close-on-backdrop no-close-on-esc title="DETAILS D'UN PAYEMENT SINGULIER AVEC CNSS" size="xl"
                            stacking>
                            <b-table ref="refInvoiceListTable" :items="filtreSalaryDetails" responsive
                                :fields="tableColumns1" primary-key="id" :busy="isPaymentSalaryDetails" show-empty
                                empty-text="Liste vide" class="position-relative">
                                <template #table-busy>
                                    <div class="text-center text-info my-2">
                                        <b-spinner class="align-middle" /> &nbsp;
                                        <strong>Chargement...</strong>
                                    </div>
                                </template>
                                <!-- Column: Invoice Status -->
                                <template #cell(status)="data">
                                    <b-badge pill :variant="`light-${resolveDetailSlaryStatusVariant(
                                        data.item.status
                                    )}`" class="text-capitalize font-small-3">
                                        {{ data.item.status == true ? "Payé" : "Non payé" }}
                                    </b-badge>
                                </template>
                                <!-- Salaire -->
                                <template #cell(employee_salary_amount)="data">
                                    <span class="text-info">
                                        {{ data.item.employee_salary_amount }} FCFA
                                    </span>
                                </template>

                                <!-- Salaire -->
                                <template #cell(service_name)="data">
                                    <span class="text-info">
                                        {{ data.item.business_recurring_order.recurring_service.name }}
                                    </span>
                                </template>

                                <!-- Column: Customer -->
                                <template #cell(customer_full_name)="data">
                                    <template>
                                        <span class="font-weight-bold d-block text-nowrap text-info">
                                            {{ data.item.business_recurring_order.package.user.full_name }}
                                        </span>
                                    </template>
                                    <span class="font-weight-bold d-block text-nowrap text-success">
                                        {{ data.item.company_ifu !== "null" ? data.item.company_ifu : "" }}
                                    </span>
                                </template>
                                <!-- Column: employee -->
                                <template #cell(employee_full_name)="data">
                                    <b-media vertical-align="center">
                                        <template #aside>
                                            <b-avatar size="32" :src="data.item.employee.profile_image" />
                                        </template>
                                        <span class="font-weight-bold d-block text-nowrap text-info">
                                            {{ data.item.employee.full_name }}
                                        </span>
                                    </b-media>
                                </template>
                                <!-- Column: Employee contract started date -->
                                <template #cell(cnss_customer_amount)="data">
                                    <span class="text-info">
                                        {{ data.item.cnss_customer_amount }} FCFA
                                    </span>
                                </template>

                                <template #cell(cnss_employee_amount)="data">
                                    <span class="text-info">
                                        {{ data.item.cnss_employee_amount }} FCFA
                                    </span>
                                </template>

                                <template #cell(its_amount)="data">
                                    <span class="text-info"> {{ data.item.its_amount }} FCFA </span>
                                </template>
                                <template #cell(vps_amount)="data">
                                    <span class="text-info"> {{ data.item.vps_amount }} FCFA </span>
                                </template>
                                <template #cell(ylomi_direct_fees)="data">
                                    <span class="text-info"> {{ data.item.ylomi_direct_fees }} FCFA </span>
                                </template>
                                <!-- AVANCE SUR SALAIRE -->
                                <template #cell(salary_advance_amount)="data">
                                    <span class="text-info">
                                        {{ data.item.salary_advance_amount == null ? 0 : data.item.salary_advance_amount }}
                                        FCFA
                                    </span>
                                </template>
                                <!-- SALAIRE NET A PRENDRE  -->
                                <template #cell(net_salary)="data">
                                    <span class="text-info">
                                        {{ net_salary(data.item.employee_salary_amount, data.item.salary_advance_amount) }}
                                        FCFA
                                    </span>
                                </template>
                                <!-- SALAIRE NET A PAYER -->
                                <template #cell(netApayer)="data">
                                    <span class="text-info">
                                        {{ netApayer(data.item.total_amount_to_paid, data.item.salary_advance_amount) }}
                                        FCFA
                                    </span>
                                </template>
                                <template #cell(total_amount_to_paid)="data">
                                    <span class="text-info">
                                        {{ data.item.total_amount_to_paid }} FCFA
                                    </span>
                                </template>

                                <!-- SALAIRE NET A PAYER -->
                                <template #cell(month_salary)="data">
                                    <span class="text-info">
                                        {{ data.item.month_salary }}
                                    </span>
                                </template>

                                <template #cell(salary_paid_date)="data">
                                    <span class="text-info">{{
                                        data.item.salary_paid_date == null ? "---------" :
                                        moment(data.item.salary_paid_date).locale("fr").format("llll").split("00:00")[0]
                                    }}</span>
                                </template>

                                <!-- Column: Actions SINGULAR WIT CNSS -->
                                <template #cell(actions)="data">

                                    <feather-icon v-if="data.item.status == 0" v-b-modal.modal-action-singular-with-cnss
                                        icon="MoreVerticalIcon" class="text-info" size="20"
                                        @click="getInfosSingulier(data.item)" />

                                    <!-- Terminate Employee Contract Modal Résilier-->
                                </template>
                            </b-table>
                            <!-- MODAL ACTIONS AVEC CNSS -->
                            <b-modal id="modal-action-singular-with-cnss" cancel-variant="outline-secondary" hide-footer
                                centered no-close-on-backdrop no-close-on-esc title="Actions sur le paiement" size="sm">
                                <b-list-group>
                                    <b-list-group-item
                                        v-if="getUser.role.slug === 'super-administrateur' || getUser.role.slug === 'assistante-administrative' || getUser.role.slug === 'comptable'"
                                        button @click="employeeWallet(salarySingularInfos, 1)">
                                        <feather-icon icon="PocketIcon" class="text-info" size="20" />
                                        <span>Portefeuille employé</span>
                                    </b-list-group-item>

                                    <b-list-group-item button
                                        v-if="(getUser.role.slug === 'super-administrateur' ||
                                            getUser.role.slug === 'charge-daffaires' ||
                                            getUser.role.slug === 'responsable-relation-client' || getUser.role.slug === 'assistante-administrative' || getUser.role.slug === 'comptable') && salarySingularInfos.status == 0"
                                        @click="showAdvanceSingularPayementMonthModal(salarySingularInfos)">
                                        <feather-icon icon="CreditCardIcon" class="text-info" size="20" />
                                        <span class="align-middle ml-50">Avance sur salaire</span>
                                    </b-list-group-item>

                                    <b-list-group-item button v-if="getUser.role.slug === 'super-administrateur'"
                                        @click="cancelPayment(salarySingularInfos, 1)">
                                        <feather-icon icon="TrashIcon" class="text-danger" size="20" />
                                        <span class="align-middle ml-50">Annuler le paiement </span>
                                    </b-list-group-item>
                                    <!-- <b-list-group-item button :disable="salarySingularInfos.status == true" v-if="getUser.role.slug === 'super-administrateur' || getUser.role.slug === 'comptable' && salaryInfos.status == 0"  @click="confirmSingularSalaryPayment(salarySingularInfos, 1)">
                                    <feather-icon icon="CheckIcon" class="text-success" size="20" />
                                    <span class="align-middle ml-50">Confirmer le Paiement</span>
                                </b-list-group-item> -->

                                    <!-- <b-list-group-item button v-if="getUser.role.slug === 'super-administrateur' " @click="cancelSingularSalaryPayment(salarySingularInfos, 1)">
                                    <feather-icon icon="TrashIcon" class="text-danger" size="20" />
                                    <span class="align-middle ml-50">Annuler le paiement </span>
                                </b-list-group-item>

                                <b-list-group-item button v-b-modal.modal-generate-link @click="clikedSigular(salarySingularInfos)">
                                    <feather-icon icon="LinkIcon" class="text-success" size="20" />
                                    <span class="align-middle ml-50">Générer lien de payement au client</span>
                                </b-list-group-item>

                                <b-list-group-item button v-if="getUser.role.slug === 'super-administrateur' " @click="showAdvanceSingularPayementMonthModal(salarySingularInfos)">
                                    <feather-icon icon="CreditCardIcon" class="text-info" size="20" />
                                    <span class="align-middle ml-50">Avance sur salaire</span>
                                </b-list-group-item>

                                <b-list-group-item button v-if="getUser.role.slug === 'super-administrateur' " @click="blockSingularSalaryPayment(salarySingularInfos, 1)">
                                    <feather-icon icon="ShuffleIcon" class="text-danger" size="20" />
                                    <span v-if="salarySingularInfos.auto_send == true"> Bloqué le salaire</span>
                                    <span v-else>Débloqué le salaire</span>
                                </b-list-group-item> -->
                                </b-list-group>

                            </b-modal>

                        </b-modal>

                        <!--MODAL FILTRE CNSS -->
                        <b-modal id="modal-filtre-cnss" cancel-variant="outline-secondary" hide-footer centered
                            no-close-on-backdrop no-close-on-esc title="Filtre Cnss" size="xl" stacking>
                            <b-table ref="refInvoiceListTable" :items="filtreCnss" responsive :fields="tableColumnsCnss"
                                primary-key="id" :busy="isFiltreCnssLoading" show-empty empty-text="Liste vide"
                                class="position-relative">
                                <template #table-busy>
                                    <div class="text-center text-info my-2">
                                        <b-spinner class="align-middle" /> &nbsp;
                                        <strong>Chargement...</strong>
                                    </div>
                                </template>
                                <!-- Column: Invoice Status -->
                                <template #cell(status)="data">
                                    <b-badge pill :variant="`light-${resolveDetailSlaryStatusVariant(
                                        data.item.status
                                    )}`" class="text-capitalize font-small-3">
                                        {{ data.item.status == true ? "Payé" : "Non payé" }}
                                    </b-badge>
                                </template>
                                <!-- Salaire -->
                                <template #cell(employee_salary_amount)="data">
                                    <span class="text-info">
                                        {{ data.item.employee_salary_amount }} FCFA
                                    </span>
                                </template>

                                <!-- Salaire -->
                                <template #cell(service_name)="data">
                                    <span class="text-info">
                                        {{ data.item.business_recurring_order.recurring_service.name }}
                                    </span>
                                </template>

                                <!-- Column: Customer -->
                                <template #cell(customer_full_name)="data">
                                    <template>
                                        <span class="font-weight-bold d-block text-nowrap text-info">
                                            {{ data.item.customer_full_name }}
                                        </span>
                                    </template>
                                    <span class="font-weight-bold d-block text-nowrap text-success">
                                        {{ data.item.company_ifu !== "null" ? data.item.company_ifu : "" }}
                                    </span>
                                </template>
                                <!-- Column: employee -->
                                <template #cell(employee_full_name)="data">
                                    <b-media vertical-align="center">
                                        <template #aside>
                                            <b-avatar size="32" :src="data.item.employee.profile_image" />
                                        </template>
                                        <span class="font-weight-bold d-block text-nowrap text-info">
                                            {{ data.item.employee.full_name }}
                                        </span>
                                    </b-media>
                                </template>
                                <!-- Column: Employee contract started date -->
                                <template #cell(cnss_customer_amount)="data">
                                    <span class="text-info">
                                        {{ data.item.cnss_customer_amount }} FCFA
                                    </span>
                                </template>

                                <template #cell(cnss_employee_amount)="data">
                                    <span class="text-info">
                                        {{ data.item.cnss_employee_amount }} FCFA
                                    </span>
                                </template>

                                <template #cell(its_amount)="data">
                                    <span class="text-info"> {{ data.item.its_amount }} FCFA </span>
                                </template>
                                <template #cell(vps_amount)="data">
                                    <span class="text-info"> {{ data.item.vps_amount }} FCFA </span>
                                </template>
                                <template #cell(ylomi_direct_fees)="data">
                                    <span class="text-info"> {{ data.item.ylomi_direct_fees }} FCFA </span>
                                </template>
                                <!-- AVANCE SUR SALAIRE -->
                                <template #cell(salary_advance_amount)="data">
                                    <span class="text-info">
                                        {{ data.item.salary_advance_amount == null ? 0 : data.item.salary_advance_amount }}
                                        FCFA
                                    </span>
                                </template>
                                <!-- SALAIRE NET A PRENDRE  -->
                                <template #cell(net_salary)="data">
                                    <span class="text-info">
                                        {{ net_salary(data.item.employee_salary_amount, data.item.salary_advance_amount) }}
                                        FCFA
                                    </span>
                                </template>
                                <!-- SALAIRE NET A PAYER -->
                                <template #cell(netApayer)="data">
                                    <span class="text-info">
                                        {{ netApayer(data.item.total_amount_to_paid, data.item.salary_advance_amount) }}
                                        FCFA
                                    </span>
                                </template>
                                <template #cell(total_amount_to_paid)="data">
                                    <span class="text-info">
                                        {{ data.item.total_amount_to_paid }} FCFA
                                    </span>
                                </template>

                                <!-- SALAIRE NET A PAYER -->
                                <template #cell(month_salary)="data">
                                    <span class="text-info">
                                        {{ data.item.month_salary }}
                                    </span>
                                </template>

                                <template #cell(salary_paid_date)="data">
                                    <span class="text-info">{{
                                        data.item.salary_paid_date == null ? "---------" :
                                        moment(data.item.salary_paid_date).locale("fr").format("llll").split("00:00")[0]
                                    }}</span>
                                </template>

                            </b-table>

                            <br />
                            <hr />
                            <div>
                                <h4 class="mb-2 text-center">TOTAL CNSS</h4>
                                <b-row>
                                    <b-col>
                                        <span class="text-md  font-weight-bold">Total cnss part patronnal</span>
                                    </b-col>
                                    <b-col>
                                        <span class="text-info"> {{ totalCnssCustomer }} FCFA</span>
                                    </b-col>
                                </b-row>
                                <br />
                                <b-row>
                                    <b-col>
                                        <span class="text-md  font-weight-bold">Total cnss part employé</span>
                                    </b-col>
                                    <b-col>
                                        <span class="text-info">{{ totalCnssEmployee }} FCFA</span>
                                    </b-col>
                                </b-row>
                                <br />
                                <b-row>
                                    <b-col>
                                        <span class="text-md  font-weight-bold">{{ totalVps }} Total VPS</span>
                                    </b-col>
                                    <b-col>
                                        <span class="text-info">{{ totalIts }} FCFA</span>
                                    </b-col>
                                </b-row>
                                <br />
                                <b-row>
                                    <b-col>
                                        <span class="text-md  font-weight-bold">Total ITS</span>
                                    </b-col>
                                    <b-col>
                                        <span class="text-info">{{ totalIts }} FCFA</span>
                                    </b-col>
                                </b-row>
                                <br />
                                <hr />
                                <div class="justify-content-center">
                                    <b-row>
                                        <b-col>
                                            <span class="text-md  font-weight-bold">Total CNSS</span>
                                        </b-col>
                                        <b-col>
                                            <span class="text-info">{{ totalCnss }} FCFA</span>
                                        </b-col>
                                    </b-row>
                                </div>
                            </div>

                        </b-modal>

                        <!--MODAL FILTRE DES DETAILS D'UN PAIEMENT DE SALAIRE SANS CNSS -->
                        <b-modal id="modal-filtre-details-salary-without-cnss" cancel-variant="outline-secondary"
                            hide-footer centered no-close-on-backdrop no-close-on-esc
                            title="DETAILS D'UN PAYEMENT SINGULIER SANS CNSS" size="xl" stacking>
                            <b-table ref="refInvoiceListTable" :items="filtreSalaryDetailsWithoutCnss" responsive
                                :fields="tableColumns2" primary-key="id" :busy="isPaymentSalaryDetails" show-empty
                                empty-text="Liste vide" class="position-relative">
                                <template #table-busy>
                                    <div class="text-center text-info my-2">
                                        <b-spinner class="align-middle" /> &nbsp;
                                        <strong>Chargement...</strong>
                                    </div>
                                </template>
                                <!-- Column: Invoice Status -->
                                <template #cell(status)="data">
                                    <b-badge pill :variant="`light-${resolveDetailSlaryStatusVariant(
                                        data.item.status
                                    )}`" class="text-capitalize font-small-3">
                                        {{ data.item.status == true ? "Payé" : "Non payé" }}
                                    </b-badge>
                                </template>
                                <!-- Salaire -->
                                <template #cell(assurance_amount)="data">
                                    <span class="text-info">
                                        {{ data.item.assurance_amount }} FCFA
                                    </span>
                                </template>
                                <!-- Salaire -->
                                <template #cell(employee_salary_amount)="data">
                                    <span class="text-info">
                                        {{ data.item.employee_salary_amount }} FCFA
                                    </span>
                                </template>

                                <!-- Salaire -->
                                <template #cell(service_name)="data">
                                    <span class="text-info">
                                        {{ data.item.business_recurring_order.recurring_service.name }}
                                    </span>
                                </template>
                                <template #cell(month_salary)="data">
                                    <span class="text-info">
                                        {{ data.item.month_salary }}
                                    </span>
                                </template>

                                <!-- Column: Customer -->
                                <template #cell(customer_full_name)="data">
                                    <template>
                                        <span class="font-weight-bold d-block text-nowrap text-info">
                                            {{ data.item.business_recurring_order.package.user.full_name }}
                                        </span>
                                    </template>
                                    <!-- <span class="font-weight-bold d-block text-nowrap text-success">
                                    {{ data.item.business_recurring_order.user.company_ifu !== "null"? data.itemcompany_ifu : "" }}
                                </span> -->
                                </template>
                                <!-- Column: employee -->
                                <template #cell(employee_full_name)="data">
                                    <b-media vertical-align="center">
                                        <template #aside>
                                            <b-avatar size="32" :src="data.item.employee.profile_image" />
                                        </template>
                                        <span class="font-weight-bold d-block text-nowrap text-info">
                                            {{ data.item.employee.full_name }}
                                        </span>
                                    </b-media>
                                </template>

                                <template #cell(ylomi_direct_fees)="data">
                                    <span class="text-info"> {{ data.item.ylomi_direct_fees }} FCFA </span>
                                </template>
                                <!-- AVANCE SUR SALAIRE -->
                                <template #cell(salary_advance_amount)="data">
                                    <span class="text-info">
                                        {{ data.item.salary_advance_amount == null ? 0 : data.item.salary_advance_amount }}
                                        FCFA
                                    </span>
                                </template>
                                <!-- SALAIRE NET A PRENDRE  -->
                                <template #cell(net_salary)="data">
                                    <span class="text-info">
                                        {{ net_salary(data.item.employee_salary_amount, data.item.salary_advance_amount) }}
                                        FCFA
                                    </span>
                                </template>
                                <!-- SALAIRE NET A PAYER -->
                                <template #cell(netApayer)="data">
                                    <span class="text-info">
                                        {{ netApayer(data.item.total_amount_to_paid, data.item.salary_advance_amount) }}
                                        FCFA
                                    </span>
                                </template>
                                <template #cell(total_amount_to_paid)="data">
                                    <span class="text-info">
                                        {{ data.item.total_amount_to_paid }} FCFA
                                    </span>
                                </template>

                                <template #cell(salary_paid_date)="data">
                                    <span class="text-info">{{
                                        data.item.salary_paid_date == null ? "---------" :
                                        moment(data.item.salary_paid_date).locale("fr").format("llll").split("00:00")[0]
                                    }}</span>
                                </template>
                                <!-- Column: Actions -->

                                <!-- Column: Actions SINGULAR WITHOUT CNSS -->
                                <template #cell(actions)="data"
                                    v-if="getUser.role.slug === 'super-administrateur' || getUser.role.slug === 'assistante-administrative' || getUser.role.slug === 'comptable' || getUser.role.slug === 'responsable-relation-client'">

                                    <feather-icon v-if="data.item.status == 0" v-b-modal.modal-action-singular-without-cnss
                                        icon="MoreVerticalIcon" class="text-info" size="20"
                                        @click="getInfosSingulier(data.item)" />

                                    <!-- Terminate Employee Contract Modal Résilier-->
                                </template>
                            </b-table>
                            <!-- MODAL ACTIONS SANS CNSS -->
                            <b-modal id="modal-action-singular-without-cnss" cancel-variant="outline-secondary" hide-footer
                                centered no-close-on-backdrop no-close-on-esc title="Actions sur le paiement" size="sm">
                                <b-list-group>
                                    <!-- <b-list-group-item button :disable="salarySingularInfos.status == true" v-if="getUser.role.slug === 'super-administrateur' || getUser.role.slug === 'comptable' && salaryInfos.status == 0"  @click="confirmSingularSalaryPaymentWithoutCnss(salarySingularInfos, 0)">
                                    <feather-icon icon="CheckIcon" class="text-success" size="20" />
                                    <span class="align-middle ml-50">Confirmer le Paiement</span>
                                </b-list-group-item> -->

                                    <b-list-group-item
                                        v-if="getUser.role.slug === 'super-administrateur' || getUser.role.slug === 'assistante-administrative' || getUser.role.slug === 'comptable'"
                                        button @click="employeeWallet(salarySingularInfos, 0)">
                                        <feather-icon icon="PocketIcon" class="text-info" size="20" />
                                        <span>Portefeuille employé</span>
                                    </b-list-group-item>

                                    <b-list-group-item button
                                        v-if="(getUser.role.slug === 'super-administrateur' ||
                                            getUser.role.slug === 'charge-daffaires' ||
                                            getUser.role.slug === 'responsable-relation-client' || getUser.role.slug === 'assistante-administrative' || getUser.role.slug === 'comptable') && salarySingularInfos.status == 0"
                                        @click="showAdvanceSingularPayementMonthModalWithoutCnss(salarySingularInfos)">
                                        <feather-icon icon="CreditCardIcon" class="text-info" size="20" />
                                        <span class="align-middle ml-50">Avance sur salaire</span>
                                    </b-list-group-item>

                                    <b-list-group-item button v-if="getUser.role.slug === 'super-administrateur'"
                                        @click="cancelPaymentSansCnss(salarySingularInfos, 0)">
                                        <feather-icon icon="TrashIcon" class="text-danger" size="20" />
                                        <span class="align-middle ml-50">Annuler le paiement </span>
                                    </b-list-group-item>
                                </b-list-group>

                            </b-modal>
                        </b-modal>

                        <div class="d-flex justify-content-between mt-2 flex-wrap">
                            <b-button :disabled="metaData.prev_page_url == null" variant="outline-secondary" @click="
                                applyLoadMoreBusinessOrderPaymentHistoryAction(metaData.prev_page_url)
                                ">
                                <span class="text-white text-nowrap font-medium-2">Page précédente</span>
                            </b-button>

                            <div>
                                <span class="text-white font-medium-2">{{ metaData.current_page }} sur {{ metaData.last_page
                                }} pages</span>
                            </div>
                            <div>
                                <span class="text-white font-medium-2">Total :{{ total }}</span>
                            </div>
                            <b-button :disabled="metaData.next_page_url == null" variant="outline-secondary" @click="
                                applyLoadMoreBusinessOrderPaymentHistoryAction(metaData.next_page_url)
                                ">
                                <span class="text-nowrap font-medium-1 text-white">Page suivante</span>
                            </b-button>
                        </div>
                        <!-- MODAL DE DETAIL DE L'HISTORIQUE DE PAYEMENT DU CLIENT -->
                    </b-card>
                </b-tab>
            </b-tabs>

        </b-card>
        <!-- Modal D'avance Mensuel avec CNSS-->
        <b-modal id="modal-payment-advance-month" cancel-variant="outline-secondary" hide-footer centered
            no-close-on-backdrop no-close-on-esc title="Avance sur Paiement Mensuel">
            <validation-observer #default="{ }" ref="advancePaymentMonth">
                <!-- Form -->
                <b-form class="p-2" autocomplete="off" @reset.prevent="hideModal"
                    @submit.prevent="applyAdvancePaymentAction(1)">
                    <b-form-group label="Montant" label-for="input">
                        <validation-provider #default="{ errors }" name="montant" rules="required">
                            <b-form-input id="montant" v-model="payload.salary_advance_amount" type="number"
                                :state="errors.length > 0 ? false : null" placeholder="Montant de l'avance" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>

                    <!-- Form Actions -->
                    <div class="d-flex mt-2">
                        <b-button variant="primary" class="mr-2" type="submit" :disabled="isAdvancePaymentLoading">
                            <div v-if="isAdvancePaymentLoading">
                                <span> Chargement ... </span>
                                <b-spinner small />
                            </div>
                            <span v-else>Soumettre</span>
                        </b-button>
                        <b-button type="reset" variant="outline-secondary" @click="hideModalPaymentAdvanceMonthForm()">
                            Annuler
                        </b-button>
                    </div>
                </b-form>
            </validation-observer>
        </b-modal>

        <!-- Modal D'avance Mensuel sans CNSS--->
        <b-modal id="modal-payment-advance-month-without-cnss" cancel-variant="outline-secondary" hide-footer centered
            no-close-on-backdrop no-close-on-esc title="Avance sur Paiement Mensuel">
            <validation-observer #default="{ }" ref="advancePaymentMonth">
                <!-- Form -->
                <b-form class="p-2" autocomplete="off" @reset.prevent="hideModal"
                    @submit.prevent="applyAdvancePaymentAction(0)">
                    <b-form-group label="Montant" label-for="input">
                        <validation-provider #default="{ errors }" name="montant" rules="required">
                            <b-form-input id="montant" v-model="payload.salary_advance_amount" type="number"
                                :state="errors.length > 0 ? false : null" placeholder="Montant de l'avance" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>

                    <!-- Form Actions -->
                    <div class="d-flex mt-2">
                        <b-button variant="primary" class="mr-2" type="submit" :disabled="isAdvancePaymentLoading">
                            <div v-if="isAdvancePaymentLoading">
                                <span> Chargement ... </span>
                                <b-spinner small />
                            </div>
                            <span v-else>Soumettre</span>
                        </b-button>
                        <b-button type="reset" variant="outline-secondary"
                            @click="hideModalPaymentAdvanceMonthFormWithoutCnss()">
                            Annuler
                        </b-button>
                    </div>
                </b-form>
            </validation-observer>
        </b-modal>
        <!-- modal de téléchargement du fichier de payement -->


        <!-- Modal Générer lien de paiment-->
        <b-modal id="modal-generate-link" cancel-variant="outline-secondary" hide-footer centered no-close-on-backdrop
            no-close-on-esc title="Générer lien de paiement" size="xl" stacking>
            <b-table ref="refInvoiceListTable" :items="generate" responsive :fields="tableColumnsLink" primary-key="id"
                show-empty empty-text="Liste vide" class="position-relative">
                <template #table-busy>
                    <div class="text-center text-info my-2">
                        <b-spinner class="align-middle" /> &nbsp;
                        <strong>Chargement...</strong>
                    </div>
                </template>
                <!-- Lien -->
                <template #cell(link)="data">
                    <span> {{ data.item.paymentLink }} </span>
                </template>

                <!-- Column: Actions -->
                <template #cell(actions)="data">
                    <div class="text-nowrap">
                        <b-dropdown variant="link" toggle-class="p-0" no-caret>
                            <template #button-content>
                                <feather-icon icon="MoreVerticalIcon" size="25"
                                    class="align-middle text-center text-info" />
                            </template>

                            <b-dropdown-item :id="`deploy${data.item.id}`" class="cursor-pointer itemActions"
                                @click="copyPaymentLink()">
                                <feather-icon icon="NavigationIcon" class="text-success" size="20" />
                                <span class="align-middle ml-50">Copier</span>
                            </b-dropdown-item>
                        </b-dropdown>
                    </div>

                </template>
            </b-table>
        </b-modal>

        <!-- Marquer le salaire comme payé -->
        <b-modal id="mark-payment-modal" cancel-variant="outline-secondary" hide-footer centered no-close-on-backdrop
            no-close-on-esc title="Marquer les salaires reçus">
            <validation-observer #default="{ }" ref="markSalaryForm">
                <!-- Form -->
                <b-form class="p-2" autocomplete="off" @reset.prevent="hideModalSelectedClient()"
                    @submit.prevent="applyMarkSalaryPayment()">
                    <b-form-group label="Liste des entreprise" label-for="role">
                        <validation-provider #default="{ errors }" name="entreprise" rules="required">
                            <b-form-select v-if="payementInformations.length === 1 && payementInformations[0].disabled"
                                id="payment_id" multiple v-model="selectedClient" :options="payementInformations" />

                            <!-- <MultiSelect v-model="selectedPayment" :options="payementInformations" :filter="true" optionLabel="full_name" placeholder="Selectionner un salaire"/> -->

                            <!-- <multiselect
                  v-model="selectedPayment"
                    :options="payementInformations"
                    :close-on-select="true"
                    :clear-on-select="false"
                    placeholder="Select one"
                    label="full_name"
                    track-by="full_name">
                </multiselect>  -->
                            <!-- <vue-multi-select
                    v-model="selectedPayment"
                    search
                    :filters="filters"
                    :options="options"
                    :selectOptions="paymentSelectOption">

                    
                </vue-multi-select> -->


                            <v-select v-model="selectedClient" :reduce="(item) => item" label="full_name"
                                :options="payementInformations">
                                <span v-if="isUnpaid == false && payementInformations.length == 0" slot="no-options"
                                    @click="$refs.select.open = false">
                                    Aucun salaire impayé
                                </span>
                                <template #option="{ full_name }">
                                    <span class="text-info">
                                        {{ full_name }}
                                    </span>
                                </template>
                            </v-select>

                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>

                    <!-- Form Actions -->
                    <div class="d-flex mt-2">
                        <!-- <b-button
                    :disabled="isMarkSalaryLoading"
                    variant="primary"
                    class="mr-2"
                    type="submit"
                  >
                    <div v-if="isMarkSalaryLoading">
                      <span> Chargement ... </span>
                      <b-spinner small />
                    </div>
                    <span v-else>Marquer</span>
                  </b-button> -->
                        <b-button type="reset" variant="outline-secondary" @click="hideModalSelectedClient()">
                            Annuler
                        </b-button>
                    </div>
                </b-form>
            </validation-observer>
        </b-modal>


        <!-- List Proposition Modal -->
        <b-modal id="modal-unpaid-salary-employee" cancel-variant="outline-secondary" hide-footer centered
            no-close-on-backdrop no-close-on-esc title="Liste des salaires impayés" size="xl" stacking
            @hidden="clearModalContent">
            <b-row>
                <b-col lg="10" md="10" sm="6">
                    <label>Sélectionner le client concerné</label>
                    <v-select v-model="selectedClient" :reduce="(customer) => customer.id" label="name"
                        :options="customerOptions" clearable @input="onInput">
                        <template v-slot:no-options>
                            <b-spinner v-if="isCustomersWithoutPaginationLoading" style="width: 2.5rem; height: 2.5rem"
                                class="align-middle text-info" />
                            <span v-else class="mt-1 font-medium-1">Aucun client ne correspond</span>
                        </template>
                    </v-select>
                </b-col>
                <b-col v-if="unpaidLoading">
                    <b-button variant="primary" lg="2" md="2" sm="6" class="mt-2">Chargement...</b-button>
                </b-col>
                <b-col v-else>
                    <b-button variant="primary" lg="2" md="2" sm="6" @click="applyGetBusinessUnpaidEmployeesAction()"
                        class="mt-2">Filtrer</b-button>
                </b-col>
            </b-row>
            <br />
            <br />


            <b-form-checkbox v-model="selectAllPayments" @change="toggleSelectAllPayments" class="mb-2">
                Sélectionner tous les paiements
            </b-form-checkbox>
            <b-table ref="refInvoiceListTable" :items="unPaidEmployees" responsive :fields="tableUnpaid" primary-key="id"
                :busy="isUnpaid" show-empty empty-text="Liste vide" class="position-relative">
                <!-- <template #cell(checkbox)="data">
          <b-form-checkbox
            v-model="selectAll"
            :indeterminate="isIndeterminate"
            @change="toggleCheckAll"
          ></b-form-checkbox>
        </template> -->



                <template #cell(checkbox)="data">
                    <b-form-checkbox v-model="selectedPayment" :value="data.item.id"
                        :disabled="data.item.status == true"></b-form-checkbox>
                </template>

                <template #table-busy>
                    <div class="text-center text-info my-2">
                        <b-spinner class="align-middle" /> &nbsp;
                        <strong>Chargement...</strong>
                    </div>
                </template>

                <!-- Column: Invoice Status -->
                <!-- <template #cell(is_rejected)="data">
              <b-badge
                pill
                :variant="`light-${resolvePropositionStatusVariant(data.item.id)}`"
                class="text-capitalize font-small-3"
              >
                {{ resolveStatusPropositionText(data.item.id) }}
              </b-badge>
            </template> -->
                <template #cell(customer)="data">
                    <span> {{ data.item.full_name }} </span>
                </template>
                <!-- Salaire -->
                <template #cell(salary)="data">
                    <span> {{ data.item.employee_salary }} FCFA </span>
                </template>
                <!-- Salaire -->
                <template #cell(ylomi_direct_fees)="data">
                    <span> {{ data.item.ylomi_direct_fees }} FCFA </span>
                </template>

                <!-- Salaire -->
                <template #cell(total_amount_to_paid)="data">
                    <span> {{ data.item.total }} FCFA </span>
                </template>

                <!-- AVANCE SUR SALAIRE -->
                <template #cell(salary_advance_amount)="data">
                    <span class="text-info">
                        {{ data.item.salary_advance_amount == null ? 0 : data.item.salary_advance_amount }} FCFA
                    </span>
                </template>
                <!-- SALAIRE NET A PRENDRE  -->
                <template #cell(net_salary)="data">
                    <span class="text-info">
                        {{ net_salary(data.item.employee_salary, data.item.salary_advance_amount) }} FCFA
                    </span>
                </template>
                <!-- SALAIRE NET A PAYER -->
                <template #cell(netApayer)="data">
                    <span class="text-info">
                        {{ netApayer(data.item.total, data.item.salary_advance_amount) }} FCFA
                    </span>
                </template>


                <!-- Column: employee -->
                <template #cell(employee)="data">
                    <b-media vertical-align="center">
                        <template #aside>
                            <b-avatar size="32" :src="data.item.employee_full_name" />
                        </template>
                        <span class="font-weight-bold d-block text-nowrap">
                            {{ data.item.employee_full_name }}
                        </span>
                        <small class="text-success font-small-3">{{
                            data.item.employee_full_name
                        }}</small>
                    </b-media>
                </template>
                <template #cell(month)="data">
                    <span> {{ data.item.month_salary }} </span>
                </template>
                <template #cell(year)="data">
                    <span> {{ data.item.year }} </span>
                </template>


                <!-- <template #cell(rejection_reason)="data">
              <span class="text-info">
                {{
                  data.item.rejection_reason != null
                    ? data.item.rejection_reason
                    : "--------"
                }}
              </span>
            </template> -->


                <!-- Column: Actions -->
                <template #cell(actions)="data">
                    <div>
                        <feather-icon v-b-modal.modal-action-unpaid icon="MoreVerticalIcon" class="text-info" size="20"
                            @click="getUnpaidInfos(data.item)" />
                    </div>
                </template>
            </b-table>
            <hr />
            <div>
                <h4 class="mb-2 text-center">TOTAL PAIEMENT</h4>
                <b-row>
                    <b-col>
                        <span class="text-md  font-weight-bold">Total salaire net des employés</span>
                    </b-col>
                    <b-col>
                        <span class="text-info"> {{ totalSalaireNetUnpaid }} FCFA</span>
                    </b-col>
                </b-row>
                <br />

                <b-row>
                    <b-col>
                        <span class="text-md  font-weight-bold">Total Commission Ylomi</span>
                    </b-col>
                    <b-col>
                        <span class="text-info">{{ totalCommissionUnpaid }} FCFA</span>
                    </b-col>
                </b-row>
                <br />

                <hr />
                <div class="justify-content-center">
                    <b-row>
                        <b-col>
                            <span class="text-md  font-weight-bold">Total NET A PAYER</span>
                        </b-col>
                        <b-col>
                            <span class="text-info">{{ totalAmountSalaryUnpaid }} FCFA</span>
                        </b-col>
                    </b-row>
                </div>
            </div>
            <div class="d-flex flex-column text-md-center">

                <div class="p-2">
                    <b-button :disabled="selectedPayment.length == 0" variant="outline-success"
                        @click="applyMarkSalaryPayment">
                        <div v-if="isMarkSalaryLoading">
                            <span> Chargement ... </span>
                            <b-spinner small />
                        </div>
                        <span v-else>Soumettre</span>

                    </b-button>
                </div>
            </div>

        </b-modal>

        <!-- MODAL ACTIONS SANS CNSS -->
        <b-modal id="modal-action-unpaid" cancel-variant="outline-secondary" hide-footer centered no-close-on-backdrop
            no-close-on-esc title="Actions sur le paiement" size="sm">
            <b-list-group>
                <b-list-group-item button v-if="getUser.role.slug === 'super-administrateur'"
                    @click="cancelUnpaidPayment(unpaidInfos)">
                    <feather-icon icon="TrashIcon" class="text-danger" size="20" />
                    <span class="align-middle ml-50">Annuler le paiement </span>
                </b-list-group-item>

            </b-list-group>

        </b-modal>

        <!-- Appliquer Cnss aux employés -->
        <b-modal id="apply-cnss-modal" cancel-variant="outline-secondary" hide-footer centered no-close-on-backdrop
            no-close-on-esc title="Appliquer Cnss aux employés">
            <validation-observer #default="{ }" ref="markSalaryForm">
                <!-- Form -->
                <b-form class="p-2" autocomplete="off" @reset.prevent="hideModalApplyCnss()"
                    @submit.prevent="applyEmployeeCnss()">
                    <b-form-group label="Liste des clients" label-for="customer">
                        <validation-provider #default="{ errors }" name="customer" rules="required">
                            <b-form-select v-if="customerOptions.length === 1 && customerOptions[0].disabled"
                                id="payment_id" v-model="selectedCustomer" :options="customerOptions" />

                            <v-select v-model="selectedCustomer" :options="customerOptions" class="invoice-filter-select"
                                label="name" :reduce="(customer) => customer.id">
                                <template v-slot:no-options>
                                    <b-spinner v-if="isCustomersWithoutPaginationLoading"
                                        style="width: 2.5rem; height: 2.5rem" class="align-middle text-info" />
                                    <span v-else class="mt-1 font-medium-1">Aucun client ne correspond</span>
                                </template>
                            </v-select>

                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>

                    <b-form-group label="Choisissez un employé" label-for="affectation">
                        <validation-provider #default="{ errors }" name="affectation" rules="required">
                            <b-form-select v-if="employeeActifOptions.length === 1 && employeeActifOptions[0].disabled"
                                id="affectation_id" v-model="selectedActifEmployee" :options="employeeActifOptions" />

                            <v-select :selectable="isOptionActifSelectable" v-else id="affectation_id" multiple
                                v-model="selectedActifEmployee" :reduce="(affectation_id) => affectation_id"
                                label="full_name" :options="employeeActifOptions">
                                <span slot="no-options" @click="$refs.select.open = false">
                                    Chargement...
                                </span>
                                <template
                                    #option="{ profile_image, full_name, age, address, employee_salary, employee, affectation }">

                                    <b-row>
                                        <b-col md="3">
                                            <b-avatar size="80px" rounded :src="profile_image" />
                                        </b-col>
                                        <b-col md="9">
                                            <b-row>
                                                <b-col>
                                                    Nom & Prénom:
                                                    <span class="text-info">
                                                        {{ full_name }}
                                                    </span>
                                                </b-col>
                                                <b-col>
                                                    Age:<span class="text-info"> {{ age }} ans </span>
                                                </b-col>
                                            </b-row>
                                            <b-row class="">
                                                <b-col>
                                                    Addresse:
                                                    <span class="text-info">{{ address }}</span>
                                                </b-col>
                                            </b-row>
                                            <b-row class="">

                                                <b-col>
                                                    Salaire net:
                                                    <span class="text-info">
                                                        {{ employee_salary }} FCFA
                                                    </span>
                                                </b-col>
                                            </b-row>
                                        </b-col>
                                    </b-row>

                                </template>
                                <!--  <template #selected-option="affectation">
                                        {{ affectation.employee.full_name }}
                                    </template> -->
                            </v-select>

                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>

                    <!-- Form Actions -->
                    <div class="d-flex mt-2">
                        <b-button :disabled="isEmployeeApplyCnssLoading" variant="primary" class="mr-2" type="submit">
                            <div v-if="isEmployeeApplyCnssLoading">
                                <span> Chargement ... </span>
                                <b-spinner small />
                            </div>
                            <span v-else>Appliquer</span>
                        </b-button>
                        <b-button type="reset" variant="outline-secondary" @click="hideModalApplyCnss()">
                            Annuler
                        </b-button>
                    </div>
                </b-form>
            </validation-observer>
        </b-modal>

        <!-- Valider la prestation d'un mois-->
        <b-modal id="download-payment-modal" cancel-variant="outline-secondary" hide-footer centered no-close-on-backdrop
            no-close-on-esc title="Fiche de paiement">
            <validation-observer #default="{ }" ref="paymentDownloadForm">
                <!-- Form -->
                <b-form class="p-2" autocomplete="off" @reset.prevent="hideModalDowloadPayment()"
                    @submit.prevent="applyDownloadPayment()">
                    <b-form-group label="Liste des entreprise" label-for="role">
                        <validation-provider #default="{ errors }" name="entreprise" rules="required">
                            <b-form-select v-if="customerOptions.length === 1 &&
                                customerOptions[0].disabled
                                " id="charge_daffaire_id" v-model="payloadDownload.user_id" :options="customerOptions" />
                            <v-select v-model="payloadDownload.user_id" :options="customerOptions"
                                class="invoice-filter-select" label="name" :reduce="(customer) => customer.id">
                                <template v-slot:no-options>
                                    <b-spinner v-if="isCustomersWithoutPaginationLoading"
                                        style="width: 2.5rem; height: 2.5rem" class="align-middle text-info" />
                                    <span v-else class="mt-1 font-medium-1">Aucun client ne correspond</span>
                                </template>
                            </v-select>

                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>

                    <b-form-group label="Prestation du mois de " label-for="month">
                        <validation-provider #default="{ errors }" name="entreprise" rules="required">
                            <v-select id="month" v-model="payloadDownload.month" :options="monthOptions" label="text"
                                :reduce="(item) => item.value">
                                <span slot="no-options" @click="$refs.select.open = false">
                                    Chargement...
                                </span>
                            </v-select>
                            <!-- <b-form-select
                      id="month"
                      v-model="payload.month"
                      :options="monthOptions"
                      multiple
                    /> -->

                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>

                    <b-form-group label="Année" label-for="year">
                        <validation-provider #default="{ errors }" name="entreprise" rules="required">
                            <v-select id="year" v-model="payloadDownload.year" :options="yearOptions"
                                :reduce="(item) => item.value">
                                <span slot="no-options" @click="$refs.select.open = false">
                                    Chargement...
                                </span>
                            </v-select>
                            <!-- <b-form-select
                      id="month"
                      v-model="payload.month"
                      :options="monthOptions"
                      multiple
                    /> -->

                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>

                    <!-- Form Actions -->
                    <div class="d-flex mt-2">
                        <b-button :disabled="isdownloadPaymentLoading" variant="primary" class="mr-2" type="submit">
                            <div v-if="isdownloadPaymentLoading">
                                <span> Chargement ... </span>
                                <b-spinner small />
                            </div>
                            <span v-else>Télécharger</span>
                        </b-button>
                        <b-button type="reset" variant="outline-secondary" @click="hideModalDownloadPayment()">
                            Annuler
                        </b-button>
                    </div>
                </b-form>


            </validation-observer>
        </b-modal>

        <!-- modal de téléchargement du fichier de payement -->
        <b-modal id="modal-new-payement-operation" cancel-variant="outline-secondary" hide-footer centered
            no-close-on-backdrop no-close-on-esc title="Téléchargement du fichier de paiement des employés.">
            <validation-observer #default="{ }" ref="downloadFile">
                <!-- Form -->
                <b-form class="p-2" autocomplete="off" @reset.prevent="hideModalPaymentFileModal"
                    @submit.prevent="applyEmployeePayementFile">
                    <!-- Email -->
                    <validation-provider #default="{ errors }" name="type_file" rules="required">

                        <b-form-group label="Type de fichier" label-for="type_file"
                            :state="errors.length > 0 ? false : null">
                            <v-select id="type_file" v-model="payement.type_file" :reduce="(item) => item.value"
                                label="name" :options="[
                                    { name: 'Bulk', value: 'BULK' },
                                    { name: 'Pdf', value: 'PDF' },
                                ]" />
                            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                {{ errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <b-form-group label="Liste des entreprise" label-for="role" v-if="payement.type_file == 'PDF'">
                        <validation-provider #default="{ errors }" name="entreprise" rules="required">
                            <b-form-select v-if="customerOptions.length === 1 &&
                                customerOptions[0].disabled
                                " id="client" v-model="payement.user_id" :options="customerOptions" />
                            <v-select v-model="payement.user_id" :options="customerOptions" class="invoice-filter-select"
                                label="name" :reduce="(customer) => customer.id">
                                <template v-slot:no-options>
                                    <b-spinner v-if="isCustomersWithoutPaginationLoading"
                                        style="width: 2.5rem; height: 2.5rem" class="align-middle text-info" />
                                    <span v-else class="mt-1 font-medium-1">Aucun client ne correspond</span>
                                </template>
                            </v-select>

                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>

                    <validation-provider #default="{ errors }" name="is_in_ylomi_program" rules="required">

                        <b-form-group label="Participe au programme YLOMI ?" label-for="is_in_ylomi_program"
                            :state="errors.length > 0 ? false : null">
                            <v-select id="is_in_ylomi_program" v-model="payement.is_in_ylomi_program"
                                :reduce="(item) => item.value" label="name" :options="[
                                    { name: 'Oui', value: 1 },
                                    { name: 'Non', value: 0 },
                                ]" />
                            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                {{ errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>



                    <validation-provider #default="{ errors }" name="month" rules="required">
                        <b-form-group label="Mois de la prestation à payer" label-for="month"
                            :state="errors.length > 0 ? false : null">
                            <v-select v-model="payement.month" :options="monthOptions" label="text"
                                :reduce="(item) => item.value" />
                            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                {{ errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <b-form-group label="Année" label-for="year">
                        <validation-provider #default="{ errors }" name="entreprise" rules="required">
                            <v-select id="year" v-model="payement.year" :options="yearOptions"
                                :reduce="(item) => item.value">
                                <span slot="no-options" @click="$refs.select.open = false">
                                    Chargement...
                                </span>
                            </v-select>
                            <!-- <b-form-select
                      id="month"
                      v-model="payload.month"
                      :options="monthOptions"
                      multiple
                    /> -->

                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>

                    <validation-provider #default="{ errors }" name="mobile_money_account" rules="required"
                        v-if="payement.type_file == 'BULK'">
                        <b-form-group label="Compte MOMO" label-for="mobile_money_account"
                            :state="errors.length > 0 ? false : null">
                            <v-select id="mobile_money_account" v-model="payement.mobile_money_account"
                                :reduce="(item) => item.value" label="name" :options="[
                                    { name: 'MTN MOBILE MONEY', value: 'MTN' },
                                    { name: 'MOOV MONEY', value: 'FLOOZ' },
                                ]" />
                            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                {{ errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <!-- Form Actions -->
                    <div class="d-flex mt-2">
                        <b-button :disabled="isPaymentLoading" variant="primary" class="mr-2" type="submit">
                            <div v-if="isPaymentLoading">
                                <span> Chargement ... </span>
                                <b-spinner small />
                            </div>
                            <span v-else>Confirmer</span>
                        </b-button>
                        <b-button type="reset" variant="outline-secondary">
                            Annuler
                        </b-button>
                    </div>
                </b-form>
            </validation-observer>
        </b-modal>

        <!-- modal de téléchargement de la fiche de paie des employés -->
        <b-modal id="download-employee-payslip" cancel-variant="outline-secondary" hide-footer centered no-close-on-backdrop
            no-close-on-esc title="Téléchargement de la fiche de paie des employés.">
            <validation-observer #default="{ }" ref="downloadFile">
                <!-- Form -->
                <b-form class="p-2" autocomplete="off" @reset.prevent="hideModalPayslip()"
                    @submit.prevent="applyDownloadPayslip()">

                    <b-form-group label="Liste des employés" label-for="employees">
                        <validation-provider #default="{ errors }" name="employees" rules="required">
                            <b-form-select v-if="customerOptions.length === 1 &&
                                customerOptions[0].disabled
                                " id="client" v-model="payslipPayload.employee_id" :options="customerOptions" />
                            <v-select v-model="payslipPayload.employee_id" :options="employeeOptions"
                                class="invoice-filter-select" label="name" :reduce="(employee) => employee.id">
                                <template v-slot:no-options>
                                    <b-spinner v-if="isEmployeesWithoutPaginationLoading"
                                        style="width: 2.5rem; height: 2.5rem" class="align-middle text-info" />
                                    <span v-else class="mt-1 font-medium-1">Aucun employé ne correspond</span>
                                </template>
                            </v-select>

                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>

                    <validation-provider #default="{ errors }" name="month" rules="required">
                        <b-form-group label="Mois de la prestation à payer" label-for="month"
                            :state="errors.length > 0 ? false : null">
                            <v-select v-model="payslipPayload.month" :options="monthOptions" label="text"
                                :reduce="(item) => item.value" />
                            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                {{ errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <b-form-group label="Année" label-for="year">
                        <validation-provider #default="{ errors }" name="year" rules="required">
                            <v-select id="year" v-model="payslipPayload.year" :options="yearOptions"
                                :reduce="(item) => item.value">
                                <span slot="no-options" @click="$refs.select.open = false">
                                    Chargement...
                                </span>
                            </v-select>
                            <!-- <b-form-select
                      id="month"
                      v-model="payload.month"
                      :options="monthOptions"
                      multiple
                    /> -->

                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>

                    <!-- Form Actions -->
                    <div class="d-flex mt-2">
                        <b-button :disabled="isPayslipLoading" variant="primary" class="mr-2" type="submit">
                            <div v-if="isPayslipLoading">
                                <span> Chargement ... </span>
                                <b-spinner small />
                            </div>
                            <span v-else>Télécharger</span>
                        </b-button>
                        <b-button type="reset" @click="hideModalPayslip()" variant="outline-secondary">
                            Annuler
                        </b-button>
                    </div>
                </b-form>
            </validation-observer>
        </b-modal>

    </div>
    <div v-else>
        <not-authorized />
    </div>
</template>

<script>
import {
    BAvatar,
    BBadge,
    BButton,
    BCard,
    BCardHeader,
    BCardBody,
    BCol,
    BDropdown,
    BDropdownItem,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BMedia,
    BPagination,
    BRow,
    BSpinner,
    BTable,
    BTooltip,
    BTabs,
    BTab,
    BListGroup,
    BListGroupItem,
    BFormCheckbox
} from "bootstrap-vue";
import {
    VueGoodTable
} from "vue-good-table";
import {
    avatarText
} from "@core/utils/filter";
import vSelect from "vue-select";
import {
    mapActions,
    mapGetters
} from "vuex";
import {
    ValidationObserver,
    ValidationProvider
} from "vee-validate";
import {
    required
} from "@validations";
import flatPickr from "vue-flatpickr-component";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
//import Multiselect from 'vue-multiselect';

//import vueMultiSelect from 'vue-multi-select';
//import 'vue-multi-select/dist/lib/vue-multi-select.css';
/* import MultiSelect from 'primevue/multiselect'; */
import axios from "axios";
import store from "@/store/index";
import $http from "@/libs/axios";

export default {
    beforeRouteLeave(to, from, next) {
        this.applyGetDirectOrdersSalaryPayments();
        next();
        // called when the route that renders this component is about to be navigated away from.
        // As with `beforeRouteUpdate`, it has access to `this` component instance.
    },
    components: {
        BSpinner,
        BRow,
        BCol,
        BFormInput,
        BDropdown,
        BDropdownItem,
        BCard,
        BCardHeader,
        BCardBody,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BBadge,
        BPagination,
        BTooltip,
        BFormSelect,
        BForm,
        BFormGroup,
        ValidationProvider,
        ValidationObserver,
        VueGoodTable,
        vSelect,
        BTabs,
        BTab,
        flatPickr,
        BListGroup,
        BListGroupItem,
        Treeselect,
        BFormCheckbox
        //vueMultiSelect,
        //Multiselect
    },

    data() {
        return {
            payslipPayload: {},
            isPayslipLoading: false,
            isUnpaidEmployeesLoading: false,
            selectedEmployeeId: [],
            unPaidEmployees: [],
            unpaidLoading: false,
            selectEmployeeId: [],
            totalWithoutCnss: 0,
            selectedClient: null,
            isMakingSoumissionLoading: false,
            tableUnpaid: [
                {
                    key: 'checkbox',
                    label: 'Checkbox',
                    sortable: true,
                    class: "font-medium-1",
                },
                {
                    key: "customer",
                    label: "Client",
                    sortable: true,
                    class: "font-medium-1",
                },
                {
                    key: "employee",
                    label: "Employés",
                    sortable: true,
                    class: "font-medium-1",
                },
                {
                    key: "salary",
                    label: "Salaire net",
                    sortable: true,
                    class: "font-medium-1",
                },
                {
                    key: "ylomi_direct_fees",
                    label: "Commission Ylomi",
                    sortable: true,
                    class: "font-medium-1",
                },
                {
                    key: "total_amount_to_paid",
                    label: "Montant total",
                    sortable: true,
                    class: "font-medium-1",
                },
                {
                    key: "salary_advance_amount",
                    label: "Avance sur salaire",
                    sortable: true,
                    class: "font-medium-1",
                },
                {
                    key: "net_salary",
                    label: "Salaire à prendre",
                    sortable: true,
                    class: "font-medium-1",
                },
                {
                    key: "netApayer",
                    label: "Net à payer",
                    sortable: true,
                    class: "font-medium-1",
                },
                {
                    key: "month",
                    label: "Mois",
                    sortable: true,
                    class: "font-medium-1",
                },
                {
                    key: "year",
                    label: "Année",
                    sortable: true,
                    class: "font-medium-1",
                },


                { key: "actions", class: "font-medium-1" },
            ],
            options: {
                multi: true,
                groups: false,
                labelName: 'full_name',
                labelList: 'payementInformations',
                cssSelected: option => (option.selected ? { 'background-color': '#5764c6' } : ''),
            },
            isPaymentLoading: false,
            isdownloadPaymentLoading: false,
            payloadDownload: {
                user_id: "",
                month: "",
                year: ""
            },
            applyCnssEmployeePayload: {
                actifsEmployeesAssigned: []
            },
            selectedPayment: [],
            selectAllPayments: false,
            isUnpaid: false,
            payementInformations: [],
            paymentSelectOption: [],
            payloadMarkSalaire: {
                payments: []
            },
            isMarkSalaryLoading: false,
            employeeActifOptions: [],



            isFiltre: false,
            salaryInfos: {},
            salarySingularInfos: {},
            isFiltreCnssLoading: false,
            filtreCnss: [],
            isFilterCnss: false,
            totalCnssCustomer: 0,
            totalSalaireNet: 0,
            totalCnssCustomerSalary: 0,
            totalCnssCustomerSalary: 0,
            totalCnssEmployeeSalary: 0,
            totalAssuranceEmployeeSalary: 0,
            totalVpsSalary: 0,
            totalItsSalary: 0,
            totalYlomiFeesSalary: 0,
            totalAdvanceSalary: 0,
            totalNetAfterAdvanceSalary: 0,
            totalAmountSalary: 0,
            totalCnssEmployee: 0,
            totalVps: 0,
            totalIts: 0,
            totalCnss: 0,
            totalSalaireNetUnpaid: 0,
            totalCommissionUnpaid: 0,
            totalAmountSalaryUnpaid: 0,

            rangeDate: null,
            config: {
                mode: 'range'
            },
            rangeDateFilter: "",
            phone: '',
            customerFullName: '',
            packageId: '',
            customerPhone_number: '',
            customerFull_name: '',
            clickedDetailCustomerName: '',
            clickedDetailIfu: '',
            cumulCancelPayload: {},
            paymentCumulPayload: {},
            isPaymentSalaryDetails: false,
            salaryDetails: [],
            filtreSalaryDetails: [],
            filtreSalaryDetailsWithoutCnss: [],
            isReLoadDataPaymentHistoryDirectOrders: false,
            isAdvancePaymentLoading: false,
            isFilterDirectSalaryPayment: false,
            payloadFilter: {
                user_id: "",
                month_salary: "",
                year: "",
                status: "",
                type: "",
                employee_received_salary_advance: "",
                cnss: "",
                auto_send: "",
                employee_id: "",
                ca_id: ""
            },
            customerOptions: [],
            employeeOptions: [],
            monthOptions: [{
                value: "janvier",
                text: "Janvier"
            },
            {
                value: "février",
                text: "Février"
            },
            {
                value: "mars",
                text: "Mars"
            },
            {
                value: "avril",
                text: "Avril"
            },
            {
                value: "mai",
                text: "Mai"
            },
            {
                value: "juin",
                text: "Juin"
            },
            {
                value: "juillet",
                text: "Juillet"
            },
            {
                value: "août",
                text: "Août"
            },
            {
                value: "septembre",
                text: "Septembre"
            },
            {
                value: "octobre",
                text: "Octobre"
            },
            {
                value: "novembre",
                text: "Novembre"
            },
            {
                value: "décembre",
                text: "Décembre"
            },
            ],
            statusOptions: [{
                label: "Janvier",
                value: "janvier",
            },
            {
                label: "Février",
                value: "février",
            },
            {
                label: "Mars",
                value: "mars",
            },
            {
                label: "Avril",
                value: "avril",
            },
            {
                label: "Mai",
                value: "mai",
            },
            {
                label: "Juin",
                value: "juin",
            },
            {
                label: "Juillet",
                value: "juillet",
            },
            {
                label: "Août",
                value: "août",
            },
            {
                label: "Septembre",
                value: "septembre",
            },
            {
                label: "Octobre",
                value: "octobre",
            },
            {
                label: "Novembre",
                value: "novembre",
            },
            {
                label: "Décembre",
                value: "décembre",
            },
            ],
            advanceOptions: [{
                label: "Oui",
                value: 1,
            },
            {
                label: "Non",
                value: 0,
            },
            ],
            cnssOptions: [{
                label: "Oui",
                value: 1,
            },
            {
                label: "Non",
                value: 0,
            },
            ],
            autoSendOptions: [{
                label: "Oui",
                value: 0,
            },
            {
                label: "Non",
                value: 1,
            },
            ],
            paymentStatusOptions: [{
                label: "Payé",
                value: 1,
            },
            {
                label: "Non Payé",
                value: 0,
            },
            ],
            paymentPayload: {
                month: "",
                year: "",
                status: "",
                cnss: "",
            },
            isEmployeeApplyCnssLoading: false,
            paymentHistory: [],
            managerOptions: [],
            isPaymentsLoading: true,
            rowsWithCnss: [],
            rowsWithoutCnss: [],
            clickAdvanceAmount: '',
            clickNetSalary: '',
            columns: [{
                label: "Client",
                field: "customer_full_name",
            },
            {
                label: "Mois",
                field: "month_salary",
            },
            {
                label: "Année",
                field: "year",
            },
            {
                label: "Montant total",
                field: "total_amount_to_be_paid",
            },
            {
                label: " Total Commission Ylomi",
                field: "ylomi_direct_fees",
            },
            /* {
                label: "Net à payer",
                field: "montantApayer",
            }, */
            {
                label: "Total avances",
                field: "total_salary_advance_amount",
            },
            {
                label: "Net à payer",
                field: "montantApayer",
            },
            {
                label: "Statut",
                field: "setStatus",
            },
            {
                label: "Date de paiement",
                field: "salary_paid_date",
            },
            {
                label: "Action",
                field: "action",
            },
            ],

            payement: {
            },
            selectedCustomer: "",

            businessManagerOptions: [],

            isOrderAssignmentLoading: false,

            payload: {
                month: "",
                salary_advance_amount: "",
            },

            lineItemDirectOrderId: null,
            lineItemEmployeeId: null,

            orderToAssign: {},
            currentOrder: {},
            statusFilter: null,
            avatarText,
            required,
            total: "",
            tableColumnsLink: [{
                key: "link",
                label: "Lien de paiement",
                sortable: true,
                class: "font-medium-1",
            },
            {
                key: "actions",
                class: "font-medium-1"
            },
            ],

            tableColumnsCnss: [{
                key: "customer_full_name",
                label: "Informations du client",
                sortable: true,
                class: "font-medium-1",
            },
            {
                key: "employee_full_name",
                label: "Employé",
                sortable: true,
                class: "font-medium-1",
            },

            {
                key: "service_name",
                label: "Service",
                sortable: true,
                class: "font-medium-1",
            },
            {
                key: "employee_salary_amount",
                label: "Salaire net",
                sortable: true,
                class: "font-medium-1",
            },

            {
                key: "cnss_customer_amount",
                label: "Cnss part patronale",
                sortable: true,
                class: "font-medium-1",
            },
            {
                key: "cnss_employee_amount",
                label: "Cnss part employé",
                sortable: true,
                class: "font-medium-1",
            },
            {
                key: "its_amount",
                label: "ITS",
                sortable: true,
                class: "font-medium-1",
            },
            {
                key: "vps_amount",
                label: "VPS",
                sortable: true,
                class: "font-medium-1",
            },


            {
                key: "month_salary",
                label: "Mois",
                sortable: true,
                class: "font-medium-1",
            },

            ],

            tableColumns1: [{
                key: "customer_full_name",
                label: "Informations du client",
                sortable: true,
                class: "font-medium-1",
            },
            {
                key: "employee_full_name",
                label: "Employé",
                sortable: true,
                class: "font-medium-1",
            },
            {
                key: "service_name",
                label: "Service",
                sortable: true,
                class: "font-medium-1",
            },
            {
                key: "employee_salary_amount",
                label: "Salaire net",
                sortable: true,
                class: "font-medium-1",
            },

            {
                key: "cnss_customer_amount",
                label: "Cnss part patronale",
                sortable: true,
                class: "font-medium-1",
            },
            {
                key: "cnss_employee_amount",
                label: "Cnss part employé",
                sortable: true,
                class: "font-medium-1",
            },
            {
                key: "its_amount",
                label: "ITS",
                sortable: true,
                class: "font-medium-1",
            },
            {
                key: "vps_amount",
                label: "VPS",
                sortable: true,
                class: "font-medium-1",
            },
            {
                key: "ylomi_direct_fees",
                label: "Prestation",
                sortable: true,
                class: "font-medium-1",
            },
            {
                key: "total_amount_to_paid",
                label: "Montant total",
                sortable: true,
                class: "font-medium-1",
            },
            {
                key: "salary_advance_amount",
                label: "Avance sur salaire",
                sortable: true,
                class: "font-medium-1",
            },
            {
                key: "net_salary",
                label: "Salaire à prendre",
                sortable: true,
                class: "font-medium-1",
            },
            {
                key: "netApayer",
                label: "Net à payer",
                sortable: true,
                class: "font-medium-1",
            },

            {
                key: "month_salary",
                label: "Mois",
                sortable: true,
                class: "font-medium-1",
            },
            {
                key: "status",
                label: "Statut",
                sortable: true,
                class: "font-medium-1",
            },
            {
                key: "salary_paid_date",
                label: "Date de paiement",
                sortable: true,
                class: "font-medium-1",
            },
            {
                key: "actions",
                class: "font-medium-1"
            },
            ],

            tableColumns2: [{
                key: "customer_full_name",
                label: "Informations du client",
                sortable: true,
                class: "font-medium-1",
            },
            {
                key: "employee_full_name",
                label: "Employé",
                sortable: true,
                class: "font-medium-1",
            },
            {
                key: "service_name",
                label: "Service",
                sortable: true,
                class: "font-medium-1",
            },
            {
                key: "employee_salary_amount",
                label: "Salaire net",
                sortable: true,
                class: "font-medium-1",
            },

            {
                key: "ylomi_direct_fees",
                label: "Commission Ylomi",
                sortable: true,
                class: "font-medium-1",
            },
            {
                key: "total_amount_to_paid",
                label: "Montant total",
                sortable: true,
                class: "font-medium-1",
            },
            {
                key: "assurance_amount",
                label: "Assurance",
                sortable: true,
                class: "font-medium-1",
            },
                ,
            {
                key: "salary_advance_amount",
                label: "Avance sur salaire",
                sortable: true,
                class: "font-medium-1",
            },
            {
                key: "net_salary",
                label: "Salaire à prendre",
                sortable: true,
                class: "font-medium-1",
            },
            {
                key: "netApayer",
                label: "Net à payer",
                sortable: true,
                class: "font-medium-1",
            },

            {
                key: "month_salary",
                label: "Mois",
                sortable: true,
                class: "font-medium-1",
            },

            {
                key: "status",
                label: "Statut",
                sortable: true,
                class: "font-medium-1",
            },
            {
                key: "salary_paid_date",
                label: "Date de paiement",
                sortable: true,
                class: "font-medium-1",
            },
            {
                key: "actions",
                class: "font-medium-1"
            },
            ],
            paymentLink: null,
            generate: [],
            clickedDetailPackage: {},
            selectedActifEmployee: [],
            selectedClientPayments: [],
            selectedIndex: 0,
            unpaidInfos: null,
        };
    },

    computed: {
        ...mapGetters([
            /* "isPaymentHistoryLoading", */
            "isBusinessPaymentHistoryLoading",
            "isCustomersWithoutPaginationLoading",
            "isEmployeesWithoutPaginationLoading",
        ]),
        ...mapGetters("orders", ["getBusinessOrdersPaymentHistory"]),
        ...mapGetters("auth", ["getUser"]),
        ...mapGetters("customers", ["getCustomersWithoutPagination"]),
        ...mapGetters("roles", ["getBusinessManagersList"]),
        ...mapGetters("professionals", ["getEmployeesWithoutPagination"]),

        /* btnLabel (){
            return selectedPayment = selectedPayment.length == 0 ? selectedPayment[0].name : 'Select ...'
        }, */



        salarymin() {
            const min = 0
            min === this.clickNetSalary
            return min
        },

        metaData() {
            const meta = {
                prev_page_url: "",
                next_page_url: "",
                current_page: "",
                last_page: "",
                current_page_url: "",
            };
            if (this.getBusinessOrdersPaymentHistory.withCnss != null) {
                meta.prev_page_url = this.getBusinessOrdersPaymentHistory.withCnss.prev_page_url;
                meta.next_page_url = this.getBusinessOrdersPaymentHistory.withCnss.next_page_url;
                meta.current_page = this.getBusinessOrdersPaymentHistory.withCnss.current_page;
                meta.last_page = this.getBusinessOrdersPaymentHistory.withCnss.last_page;
                meta.current_page_url = `${this.getBusinessOrdersPaymentHistory.withCnss.path}?page=${this.getBusinessOrdersPaymentHistory.current_page}`;
                this.total = this.getBusinessOrdersPaymentHistory.withCnss.total
            }
            return meta;
        },

        metaDataWithoutCnss() {
            const metaWithoutCnss = {
                prev_page_url: "",
                next_page_url: "",
                current_page: "",
                last_page: "",
                current_page_url: "",
            };
            if (this.getBusinessOrdersPaymentHistory.withoutCnss != null) {
                metaWithoutCnss.prev_page_url = this.getBusinessOrdersPaymentHistory.withoutCnss.prev_page_url;
                metaWithoutCnss.next_page_url = this.getBusinessOrdersPaymentHistory.withoutCnss.next_page_url;
                metaWithoutCnss.current_page = this.getBusinessOrdersPaymentHistory.withoutCnss.current_page;
                metaWithoutCnss.last_page = this.getBusinessOrdersPaymentHistory.withoutCnss.last_page;
                metaWithoutCnss.current_page_url = `${this.getBusinessOrdersPaymentHistory.withoutCnss.path}?page=${this.getBusinessOrdersPaymentHistory.withoutCnss.current_page}`;
                this.totalWithoutCnss = this.getBusinessOrdersPaymentHistory.withoutCnss.total;
            }
            return metaWithoutCnss;
        },
        previousMonth() {
            const current = new Date();
            current.setMonth(current.getMonth() - 1);
            return current.toLocaleString("fr", {
                month: "long"
            });
        },

        yearOptions() {
            const data = [];
            for (let i = 2021; i <= Number(new Date().getFullYear().toString()); i++) {
                data.push({
                    label: i.toString(),
                    value: i.toString(),
                });
            }
            return data;
        },
    },

    watch: {
        /* selectedClient(newClient) {
            console.log(this.selectedClient)
        this.openClientModal(newClient);
        },  */
        isEmployeesWithoutPaginationLoading(val) {
            if (val === false) {
                this.getEmployeesWithoutPagination.forEach((element) => {
                    this.employeeOptions.push({
                        id: element.id,
                        name: element.full_name,
                    });
                });
            }
        },
        isBusinessPaymentHistoryLoading(val) {
            if (val === false) {
                this.rowsWithCnss = this.customTab(this.getBusinessOrdersPaymentHistory.withCnss.data);
                this.rowsWithoutCnss = this.customTab(
                    this.getBusinessOrdersPaymentHistory.withoutCnss.data
                );
            }
        },

        isDirectOrdersLoading(val) {
            if (val === false) { }
        },
        isCustomersWithoutPaginationLoading(val) {
            if (val === false) {
                this.getCustomersWithoutPagination.forEach((element) => {
                    this.customerOptions.push({
                        id: element.id,
                        name: element.customer_full_name,
                    });
                });
            }
        },
        rangeDate(val) {
            if (val != null && val.split('to').length === 2) {
                console.log(val.split('to').length)
                this.applyFilterCnssAction(val)
            }
        },
        payloadFilter: {
            handler(newValue, oldValue) {
                const keys = Object.keys(newValue);
                keys.forEach((key) => {
                    if (newValue[key] == null) {
                        newValue[key] = "";
                    }
                });
            },
            deep: true,
        },


        /* selectedClient: {
            handler(newValue){
            console.log("Client selection",this.selectedClient);
                const keys = Object.keys(newValue);
                console.log("Keys",keys);
                keys.forEach((key) => {
                    console.log("newValue[key]",newValue[key]);
                    if (newValue[key] === null || newValue[key] === undefined) {
                        console.log("newValue",newValue[key]);
                        this.applyGetBusinessmployeeSalaryUnpaidAction()
                        newValue[key] = "";
                        console.log("Client selection",this.selectedClient);
                        
                    }
                });
                
            },
            deep: true,
        }, */
        employeeActifOptions: {
            deep: true,
            handler() {
                this.updateDisabledActifOptions();
            }
        },

        selected_payement(val) {
            this.amount_add = val.employee_salary_amount
        },
        selectedActifEmployee(val) {
            console.log('Actifs', val)
        },
        selectedCustomer(val) {
            console.log('valeur', val)
            if (val !== null) {
                console.log(this.selectedCustomer)
                /* this.$store.commit('SET_IS_SUIVIS_LOADING', true) */
                this.employeeActifOptions = []

                this.getActifsBusinessEmployeesAction(this.selectedCustomer)
                    .then((response) => {

                        console.log(response.data, "Actifs employees")
                        if (response.data.length === 0) {
                            this.employeeActifOptions.push({
                                value: "",
                                disabled: true,
                                text: "Aucun employé actif sur cette commande ",
                            });
                        } else {
                            response.data.forEach((element) => {
                                this.employeeActifOptions.push({
                                    id: element.id,
                                    label: element.employee.full_name,
                                    employee_salary: element.employee_salary,
                                    full_name: element.employee.full_name,
                                    affectation_id: element.id,
                                    employee_desired_salary: element.employee_salary,
                                    profile_image: element.employee.profile_image,
                                    full_name: element.employee.full_name,
                                    age: element.employee.age,
                                    address: element.employee.address,
                                    applied_cnss: element.applied_cnss,
                                });

                                console.log("TEST CNSS", this.employeeActifOptions)
                            })
                        }
                        // console.log(this.rows_employees_suivis)

                    })
                    .catch((error) => {
                        if (error.response.data.message === "Ce client ne dispose pas de commandes chez nous actuellement") {
                            this.employeeActifOptions.push({
                                value: "",
                                disabled: true,
                                text: "Ce client ne dispose pas de commandes chez nous actuellement",
                            });
                        }
                        /*  this.$toast({
                                     component: ToastificationContent,
                                     props: {
                                         title: "Erreur",
                                         icon: "AlertTriangleIcon",
                                         text: error.response.data.message,
                                         variant: "danger",
                                     },
                                 }, {
                                     position: "top-center",
                                 }); */
                        console.log(error)
                    })
            }
            else {
                console.Log("Aucun employé actif")
            }
        },
    },

    created() {
        this.applyGetBusinessManagersListAction();
        console.log('SALAIRE', this.getBusinessOrdersPaymentHistory)
        this.getBusinessOrdersPaymentHistory.data != null ?
            (this.rowsWithCnss = this.customTab(this.getBusinessOrdersPaymentHistory.data.withCnss.data)) :
            this.applyGetBusinessSalaryPaymentHistoryAction();

        this.getBusinessOrdersPaymentHistory.data != null ?
            (this.rowsWithoutCnss = this.customTab(
                this.getBusinessOrdersPaymentHistory.data.withoutCnss
            )) :
            this.applyGetBusinessSalaryPaymentHistoryAction();

        // this.reLoadDataPaymentHistoryBusinessOrdersAction()
    },

    mounted() {
        if (this.getEmployeesWithoutPagination.length != 0) {
            this.getEmployeesWithoutPagination.forEach((element) => {
                this.employeeOptions.push({
                    id: element.id,
                    name: element.full_name,
                });
            });
        }

        if (this.getCustomersWithoutPagination.length != 0) {
            this.getCustomersWithoutPagination.forEach((element) => {
                this.customerOptions.push({
                    id: element.id,
                    name: element.customer_full_name,
                });
            });
        }
    },

    methods: {
        applyGetDirectOrdersSalaryPayments() {
            this.$store.commit("SET_IS_BUSINESS_PAYMENT_HISTORY_LOADING", true);

            this.getBusinessOrdersSalaryPaymentsAction()
                .then(() => {
                    this.$store.commit("SET_IS_BUSINESS_PAYMENT_HISTORY_LOADING", false);
                })
                .catch(() => {
                    this.$store.commit("SET_IS_BUSINESS_PAYMENT_HISTORY_LOADING", false);
                });
        },
        ...mapActions("customers", [
            "advancePaymentAction",
            "getDirectOrdersSalaryPaymentsAction",
        ]),
        ...mapActions("roles", ["getBusinessManagersListAction"]),

        ...mapActions("orders", [
            "businessEmployeeSalaryUnpaidAction",
            "businessUnpaidEmployeesAction",
            "markSalaryPaymentAction",
            "downloadPaymentAction",
            "getActifsBusinessEmployeesAction",
            "makeApplyCnssAction",
            "cancelUnpaidPaymentAction",
            "advanceBusinessPaymentAction",
            "getBusinessOrdersSalaryPaymentsAction",
            "getBusinessSalaryPayementDetailsAction",
            "assignAnOrderToBusinessManagerAction",
            "getDirectOrdersAction",
            "getDirectOrdersAssignedToBusinessManagerAction",
            "cancelDirectOrderPaymentAction",
            "getDirectOrdersSalaryPaymentsAction",
            "loadMoreBusinessOrderPaymentHistoryAction",
            "filterBusinessOrdersSalaryPaymentsAction",
            "getSalaryPayementDetailsAction",
            "confirmSalaryCumulPaymentAction",
            "cancelCumulPaymentAction",
            "confirmSingularSalaryPaymentAction",
            "cancelSingularSalaryPaymentAction",
            "filterCnssAction"
        ]),
        ...mapActions("professionals", [
            "confirmSalaryPaiementAction",
            "blockSalaryPaiementAction",
            "unBlockSalaryPaiementAction",
        ]),
        getUnpaidInfos(item) {
            this.unpaidInfos = item
            console.log(this.unpaidInfos)
        },
        applyGetBusinessUnpaidEmployeesAction() {
            this.unpaidLoading = true
            this.isUnpaid = true
            console.log(this.selectedClient)
            this.unPaidEmployees = [],
                this.payementInformations = []
            this.totalSalaireNetUnpaid = 0,
                this.totalCommissionUnpaid = 0,
                this.totalAmountSalaryUnpaid = 0,

                this.businessUnpaidEmployeesAction(this.selectedClient)
                    .then(res => {
                        console.log("Salaires impayés", res)
                        this.unpaidLoading = false
                        this.isUnpaid = false
                        if (res.data.length != null) {
                            res.data.forEach((element) => {
                                this.payementInformations.push({
                                    month_salary: element.month_salary,
                                    year: element.year,
                                    id: element.id,
                                    employee_salary: element.employee_salary_amount,
                                    full_name: element.business_recurring_order.package.user.full_name,
                                    employee_full_name: element.employee.full_name,
                                    client_id: element.business_recurring_order.package.user.id,
                                    status: element.status,
                                    ylomi_direct_fees: element.ylomi_direct_fees,
                                    total: element.total_amount_to_paid,
                                    salary_advance_amount: element.salary_advance_amount,

                                });
                                this.unPaidEmployees = this.payementInformations
                                this.totalSalaireNetUnpaid += element.employee_salary_amount,
                                    this.totalCommissionUnpaid += element.ylomi_direct_fees,
                                    this.totalAmountSalaryUnpaid += element.total_amount_to_paid

                            });
                        }
                        else {
                            this.unPaidEmployees = [],
                                this.payementInformations = []
                        }
                        /*  if(this.selectedClient != null){
                             this.openClientModal()
                         } */

                        res.data.forEach((element) => {
                            this.paymentSelectOption.push({
                                id: element.id,
                            });
                            /* console.log('///', this.payementInformations) */
                        }

                        )
                        console.log(this.payementInformations)
                    })
                    .catch((error) => {
                        this.unpaidLoading = false
                        this.isUnpaid = false
                        console.log(error)
                    })
        },
        cancelUnpaidPayment(unpaid) {
            this.$swal
                .fire({
                    title: "Annuler",
                    text: "Êtes-vous sûr de vouloir annuler cet paiement?",
                    icon: "warning",
                    showCancelButton: true,
                    showLoaderOnConfirm: true,
                    cancelButtonText: "Non",
                    allowOutsideClick: false,
                    confirmButtonText: "Oui",
                    customClass: {
                        confirmButton: "btn btn-warning",
                        cancelButton: "btn btn-outline-primary ml-1",
                    },
                    buttonsStyling: false,
                    preConfirm: async () => {
                        await this.cancelUnpaidPaymentAction(unpaid.id)
                            .then((response) => { })
                            .catch((error) => {
                                this.$swal.showValidationMessage(`${error.response.data.message}`);
                            });
                    },
                })

                .then((response) => {
                    if (response.value) {

                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "Succès",
                                icon: "CheckIcon",
                                text: "Paiement annulé",
                                variant: "success",
                            },
                        }, {
                            position: "top-center",
                        });
                        this.payementInformations = [],
                            this.unPaidEmployees = []
                        this.totalSalaireNetUnpaid = 0,
                            this.totalCommissionUnpaid = 0,
                            this.totalAmountSalaryUnpaid = 0,

                            this.applyGetBusinessUnpaidEmployeesAction();
                        this.applyGetBusinessSalaryPaymentHistoryAction();
                        this.$bvModal.hide("modal-action-unpaid");
                    }
                })
                .catch((error) => {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Erreur",
                            icon: "AlertTriangleIcon",
                            text: error,
                            variant: "danger",
                        },
                    }, {
                        position: "top-center",
                    });
                });
        },
        cancelPaymentSansCnss(unpaid, cnss) {
            this.$swal
                .fire({
                    title: "Annuler",
                    text: "Êtes-vous sûr de vouloir annuler cet paiement?",
                    icon: "warning",
                    showCancelButton: true,
                    showLoaderOnConfirm: true,
                    cancelButtonText: "Non",
                    allowOutsideClick: false,
                    confirmButtonText: "Oui",
                    customClass: {
                        confirmButton: "btn btn-warning",
                        cancelButton: "btn btn-outline-primary ml-1",
                    },
                    buttonsStyling: false,
                    preConfirm: async () => {
                        await this.cancelUnpaidPaymentAction(unpaid.id)
                            .then((response) => { })
                            .catch((error) => {
                                this.$swal.showValidationMessage(`${error.response.data.message}`);
                            });
                    },
                })

                .then((response) => {
                    if (response.value) {

                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "Succès",
                                icon: "CheckIcon",
                                text: "Paiement annulé",
                                variant: "success",
                            },
                        }, {
                            position: "top-center",
                        });
                        this.clickedDetailPackage.status = true
                        console.log('status', this.clickedDetailPackage)
                        if (this.isFiltre == true) {
                            this.applyFilterBusinessSalaryPaymentAction(cnss)

                        } else {
                            this.showSalaireDetails(this.clickedDetailPackage, cnss);
                        }
                        this.applyGetBusinessSalaryPaymentHistoryAction();
                        this.$bvModal.hide("modal-action-singular-without-cnss");

                        this.applyGetBusinessSalaryPaymentHistoryAction();
                    }
                })
                .catch((error) => {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Erreur",
                            icon: "AlertTriangleIcon",
                            text: error,
                            variant: "danger",
                        },
                    }, {
                        position: "top-center",
                    });
                });
        },

        cancelPayment(unpaid, cnss) {
            this.$swal
                .fire({
                    title: "Annuler",
                    text: "Êtes-vous sûr de vouloir annuler cet paiement?",
                    icon: "warning",
                    showCancelButton: true,
                    showLoaderOnConfirm: true,
                    cancelButtonText: "Non",
                    allowOutsideClick: false,
                    confirmButtonText: "Oui",
                    customClass: {
                        confirmButton: "btn btn-warning",
                        cancelButton: "btn btn-outline-primary ml-1",
                    },
                    buttonsStyling: false,
                    preConfirm: async () => {
                        await this.cancelUnpaidPaymentAction(unpaid.id)
                            .then((response) => { })
                            .catch((error) => {
                                this.$swal.showValidationMessage(`${error.response.data.message}`);
                            });
                    },
                })

                .then((response) => {
                    if (response.value) {

                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "Succès",
                                icon: "CheckIcon",
                                text: "Paiement annulé",
                                variant: "success",
                            },
                        }, {
                            position: "top-center",
                        });
                        this.clickedDetailPackage.status = true
                        console.log('status', this.clickedDetailPackage)
                        if (this.isFiltre == true) {
                            this.applyFilterBusinessSalaryPaymentAction(cnss)

                        } else {
                            this.showSalaireDetails(this.clickedDetailPackage, cnss);
                        }
                        this.applyGetBusinessSalaryPaymentHistoryAction();
                        this.$bvModal.hide("modal-action-singular-with-cnss");

                        this.applyGetBusinessSalaryPaymentHistoryAction();
                    }
                })
                .catch((error) => {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Erreur",
                            icon: "AlertTriangleIcon",
                            text: error,
                            variant: "danger",
                        },
                    }, {
                        position: "top-center",
                    });
                });
        },
        onInput(newValue) {
            if (newValue === null) {
                this.unPaidEmployees = [],
                    this.totalSalaireNetUnpaid = 0,
                    this.totalCommissionUnpaid = 0,
                    this.totalAmountSalaryUnpaid = 0,
                    this.applyGetBusinessUnpaidEmployeesAction()

            }
        },
        clearModalContent() {
            this.unPaidEmployees = [],
                this.selectedClient = null,
                this.totalSalaireNetUnpaid = 0,
                this.totalCommissionUnpaid = 0,
                this.totalAmountSalaryUnpaid = 0,
                this.applyGetBusinessUnpaidEmployeesAction()
        },

        switchView(event, selectedIndex) {
            alert(event, selectedIndex);
            this.selectedIndex = selectedIndex;
        },


        async applyGetBusinessmployeeSalaryUnpaidAction() {
            this.payementInformations = []

            await this.businessEmployeeSalaryUnpaidAction()
                .then(res => {
                    console.log("Salaires impayés", res)

                    this.isUnpaid = false
                    if (res.length != 0) {
                        res.data.forEach((element) => {
                            this.payementInformations.push({
                                month_salary: element.month_salary,
                                year: element.year,
                                id: element.id,
                                employee_salary: element.employee_salary_amount,
                                full_name: element.business_recurring_order.package.user.full_name,
                                employee_full_name: element.employee.full_name,
                                client_id: element.business_recurring_order.package.user.id,
                                service: element.business_recurring_order.recurring_service_id.user.id,
                                status: element.status

                            });

                        });

                    }
                    else {
                        this.payementInformations = []
                    }

                    /* if(this.selectedClient != null){
                        this.openClientModal()
                    } */
                    /* res.data.forEach((element) => {
                    this.payementInformations.push({
                        
                        
                        full_name: element.business_recurring_order.package.user.full_name,
                        
                  
                    });
                    }); */

                    res.data.forEach((element) => {
                        this.paymentSelectOption.push({
                            id: element.id,
                        });
                        /* console.log('///', this.payementInformations) */
                    }
                    )
                    /* res.data.forEach(element => {
                        element.customKey = `${element.month_salary} ${element.year}`
                        this.payementInformations.push(element)
                    }) */

                    console.log(this.payementInformations)
                })
                .catch((error) => {
                    this.isUnpaid = false
                    console.log(error)
                })
        },

        clientSelect() {

            alert("Focus event")
            document.getElementById("customerSelect").selectedIndex = -1;
            document.getElementById("customerSelect").blur();
        },

        openClientModal() {
            /* alert('Open modal')
             console.log("Function openClientModal called with client:", this.selectedClient);
     
           this.$bvModal.show("modal-unpaid-salary-employee"); */
            // Ouvrir le modal ici si nécessaire
            console.log(this.selectedClient, "selected client")
            this.selectedClientPayments = this.payementInformations.filter(payment => payment.client_id === this.selectedClient.client_id);
            console.log(this.selectedClientPayments)
            this.unPaidEmployees = this.selectedClientPayments

        },
        clearSelectedClient() {
            this.selectedClient = null;
        },
        reloadFunction() {
            this.values = [

            ];
        },
        applySubmitPropositionAction() {
            /* console.log("----------",this.employeeActifOptions)
                if(this.employeeActifOptions.lenght !== 0){
                    this.disabledActifOptions = this.employeeActifOptions.filter(element => element.applied_cnss == 1);
                console.log("------OPTINS----",this.disabledActifOptions)
                } */
        },
        updateDisabledActifOptions() {
            console.log("----------", this.employeeActifOptions)
            if (this.employeeActifOptions.lenght !== 0) {
                this.disabledActifOptions = this.employeeActifOptions.filter(element => element.applied_cnss == 1);
                console.log("------OPTINS----", this.disabledActifOptions)
            }

        },

        showPayementBulk() {
            this.$bvModal.show('modal-new-payement-operation')
        },


        hideModalApplyCnss() {
            this.$bvModal.hide('apply-cnss-modal')
            this.selectedActifEmployee = []
            this.selectedCustomer = []
            this.applyCnssEmployeePayload = {
                actifsEmployeesAssigned: [],
            }
        },
        isOptionSelectable(option) {
            return option.affectation.status == 0;
        },
        isOptionActifSelectable(option) {
            return option.applied_cnss == 0;
        },

        applyEmployeeCnss() {
            this.isEmployeeApplyCnssLoading = true
            this.applyCnssEmployeePayload.actifsEmployeesAssigned == this.selectedActifEmployee.forEach(element => {
                console.log(element.affectation_id, "element")
                this.applyCnssEmployeePayload.actifsEmployeesAssigned.push(element.affectation_id)

                console.log(this.applyCnssEmployeePayload, " this.applyCnssEmployeePayload")
            })
            this.makeApplyCnssAction({
                payload: this.applyCnssEmployeePayload,
            })

                .then((response) => {
                    this.isEmployeeApplyCnssLoading = false
                    this.hideModalApplyCnss();
                    this.getBusinessOrdersSalaryPaymentsAction().then(async (response) => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "Succès",
                                icon: "CheckIcon",
                                text: "Cnss appliqué avec succès",
                                variant: "success",
                            },
                        }, {
                            position: "top-center",
                        });
                        this.applyGetBusinessSalaryPaymentHistoryAction()

                    })


                })
                .catch((error) => {
                    this.isEmployeeApplyCnssLoading = false
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Erreur",
                            icon: "AlertTriangleIcon",
                            text: error.response.data.message,
                            variant: "danger",
                        },
                    }, {
                        position: "top-center",
                    });
                    this.hideModalApplyCnss()
                });

        },

        hideModalPayslip() {
            this.payslipPayload = {

            }
            this.$bvModal.hide('download-employee-payslip')
        },

        applyDownloadPayslip() {
            this.$refs.downloadFile.validate().then(success => {
                if (success) {
                    this.isPayslipLoading = true
                    $http
                        .post('/business/recurring/orders/download/employees/payment/file', this.payement)
                        .then(response => {
                            this.isPaymentLoading = false
                            console.log(response)
                            this.$router.push({
                                path: "/preview/pdf",
                                query: {
                                    makeActionOnPage: false,
                                    actionType: null,
                                    isSigned: true,
                                    pdf: response.data.file_url,

                                },
                            })


                            this.$toast(
                                {
                                    component: ToastificationContent,
                                    props: {
                                        title: 'Succès',
                                        icon: 'CheckIcon',
                                        text: 'Fichier téléchargé avec succès',
                                        variant: 'success',
                                    },
                                },
                                {
                                    position: 'top-center',
                                },
                            )
                            this.hideModalPaymentFileModal()
                        })
                        .catch(error => {
                            this.isPaymentLoading = false
                            if (error.response.status == 422) {
                                this.$toast(
                                    {
                                        component: ToastificationContent,
                                        props: {
                                            title: 'Notification',
                                            icon: 'CheckIcon',
                                            text: 'Aucun employé à payé pour ce mois',
                                            variant: 'warning',
                                        },
                                    },
                                    {
                                        position: 'top-center',
                                    },
                                )
                                this.hideModalPaymentFileModal()
                            }
                            this.isPaymentLoading = false
                        })

                }
            })

        },


        // payement function
        applyEmployeePayementFile() {
            this.$refs.downloadFile.validate().then(success => {
                if (success) {
                    this.isPaymentLoading = true
                    if (this.payement.type_file == "PDF") {

                        $http
                            .post('/business/recurring/orders/download/employees/payment/file', this.payement)
                            .then(response => {
                                this.isPaymentLoading = false
                                console.log(response)
                                this.$router.push({
                                    path: "/preview/pdf",
                                    query: {
                                        makeActionOnPage: false,
                                        actionType: null,
                                        isSigned: true,
                                        pdf: response.data.file_url,

                                    },
                                })


                                this.$toast(
                                    {
                                        component: ToastificationContent,
                                        props: {
                                            title: 'Succès',
                                            icon: 'CheckIcon',
                                            text: 'Fichier téléchargé avec succès',
                                            variant: 'success',
                                        },
                                    },
                                    {
                                        position: 'top-center',
                                    },
                                )
                                this.hideModalPaymentFileModal()
                            })
                            .catch(error => {
                                this.isPaymentLoading = false
                                if (error.response.status == 422) {
                                    this.$toast(
                                        {
                                            component: ToastificationContent,
                                            props: {
                                                title: 'Notification',
                                                icon: 'CheckIcon',
                                                text: 'Aucun employé à payé pour ce mois',
                                                variant: 'warning',
                                            },
                                        },
                                        {
                                            position: 'top-center',
                                        },
                                    )
                                    this.hideModalPaymentFileModal()
                                }
                                this.isPaymentLoading = false
                            })
                    }
                    else {

                        $http
                            .post('/business/recurring/orders/download/employees/payment/file', this.payement, {
                                responseType: 'blob',
                            })
                            .then(response => {
                                this.isPaymentLoading = false


                                const url = window.URL.createObjectURL(new Blob([response.data]))
                                const link = document.createElement('a')
                                link.href = url
                                link.setAttribute(
                                    'download',
                                    `bulk-${this.payement.month}-${new Date().getFullYear()}.csv`,
                                )
                                document.body.appendChild(link)
                                link.click()

                                this.$toast(
                                    {
                                        component: ToastificationContent,
                                        props: {
                                            title: 'Succès',
                                            icon: 'CheckIcon',
                                            text: 'Fichier téléchargé avec succès',
                                            variant: 'success',
                                        },
                                    },
                                    {
                                        position: 'top-center',
                                    },
                                )
                                this.hideModalPaymentFileModal()
                            })
                            .catch(error => {
                                this.isPaymentLoading = false
                                if (error.response.status == 422) {
                                    this.$toast(
                                        {
                                            component: ToastificationContent,
                                            props: {
                                                title: 'Notification',
                                                icon: 'CheckIcon',
                                                text: 'Aucun employé à payé pour ce mois',
                                                variant: 'warning',
                                            },
                                        },
                                        {
                                            position: 'top-center',
                                        },
                                    )
                                    this.hideModalPaymentFileModal()
                                }
                                this.isPaymentLoading = false
                            })
                    }
                }
            })
        },




        employeeWallet(item, cnss) {
            console.log(item)
            console.log(item)
            this.$router.push(`/employees/wallet/${item.employee.wallet.id}`)
        },



        hideModalMarkSalary() {
            this.$bvModal.hide('modal-unpaid-salary-employee')
            this.selectedPayment = null,
                this.payloadMarkSalaire = {
                    payments: []
                }
        },

        hideModalSelectedClient() {
            this.$bvModal.hide('mark-payment-modal'),
                this.selectedClient = null

        },


        toggleSelectAllPayments() {
            if (this.selectAllPayments) {
                // Si sélectionner tout est coché, ajoutez tous les paiements au tableau sélectionné
                this.selectedPayment = this.unPaidEmployees.map(payment => payment.id);
            } else {
                // Sinon, désélectionnez tout
                this.selectedPayment = [];
            }
        },


        applyMarkSalaryPayment() {

            this.isMarkSalaryLoading = true


            this.payloadMarkSalaire.payments = this.selectedPayment

            this.markSalaryPaymentAction(this.payloadMarkSalaire)
                .then(async (response) => {
                    this.isMarkSalaryLoading = false
                    console.log(response)

                    this.$toast(
                        {
                            component: ToastificationContent,
                            props: {
                                title: 'Succès',
                                icon: 'CheckIcon',
                                text: response.message,
                                variant: 'success',
                            },
                        },
                        {
                            position: 'top-center',
                        },
                    )
                    console.log("impayés")

                    this.applyGetBusinessUnpaidEmployeesAction()
                    this.payloadMarkSalaire = {
                        payments: []
                    }
                    console.log("Selected client", this.selectedClient.client_id)
                    this.selectedPayment = [],
                        this.selectAllPayments = [],
                        this.unPaidEmployees = []


                    this.applyGetBusinessSalaryPaymentHistoryAction()
                })

                .catch(error => {
                    this.isMarkSalaryLoading = false
                    console.log(error)
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Erreur",
                            icon: "AlertTriangleIcon",
                            text: error,
                            variant: "danger",
                        },
                    }, {
                        position: "top-center",
                    });
                    this.payloadMarkSalaire = {
                        payments: []
                    }

                    this.applyGetBusinessmployeeSalaryUnpaidAction()

                })

        },

        hideModalDowloadPayment() {
            this.$bvModal.hide('download-payment-modal')
            this.payloadDownload = {
                user_id: "",
                month: "",
                year: ""
            }
        },

        applyDownloadPayment() {
            this.$refs.paymentDownloadForm.validate().then((success) => {
                if (success) {
                    this.isdownloadPaymentLoading = true,
                        this.downloadPaymentAction(this.payloadDownload)
                            .then(response => {
                                this.isdownloadPaymentLoading = false,
                                    console.log(response)
                                this.$router.push({
                                    path: "/preview/pdf",
                                    query: {
                                        makeActionOnPage: false,
                                        actionType: null,
                                        isSigned: true,
                                        pdf: response.data.file_url,

                                    },
                                });
                            })
                            .catch(error => {
                                this.isdownloadPaymentLoading = false,
                                    console.log(error)
                                this.$toast(
                                    {
                                        component: ToastificationContent,
                                        props: {
                                            title: "Error",
                                            icon: "AlertTriangleIcon",
                                            text: error.response.data.message,
                                            variant: "danger",
                                        },
                                    },
                                    {
                                        position: "top-center",
                                    }
                                );
                            })
                }
            }
            );
        },

        getInfosCumul(item) {
            console.log(item)
            this.salaryInfos = item

        },
        getInfosSingulier(item) {
            console.log(item)
            this.salarySingularInfos = item
        },

        // ACTIONS WITHOUT CNSS PAYMENT SALARY
        // DETAILS D'UN PAIEMENT DE SALAIRE
        showSalaireWithoutCnssDetails(salary, cnss) {
            this.isFiltre = false;
            this.clickedDetailPhoneNumber = salary.phone_number
            this.clickedDetailCustomerName = salary.company_full_name
            this.clickedDetailIfu = salary.company_ifu !== "null" ? salary.company_ifu : ""
            this.clickedDetailPackage = salary
            this.isPaymentSalaryDetails = true
            this.paymentPayload.user_id = salary.company_id
            this.paymentPayload.month = salary.month_salary
            this.paymentPayload.year = salary.year
            this.paymentPayload.status = salary.status ? 1 : 0
            this.paymentPayload.cnss = cnss
            this.totalSalaireNet = 0
            this.totalCnssCustomerSalary = 0
            this.totalAssuranceEmployeeSalary = 0
            this.totalYlomiFeesSalary = 0
            this.totalAdvanceSalary = 0
            this.totalNetAfterAdvanceSalary = 0
            this.totalAmountSalary = 0

            console.log("Voir salaire", salary)
            this.getBusinessSalaryPayementDetailsAction(
                this.paymentPayload,
            )
                .then((response) => {
                    this.salaryDetails = response.data;
                    this.salaryDetails.forEach(element => {
                        this.totalSalaireNet += element.employee_salary_amount
                        this.totalCnssCustomerSalary += element.cnss_customer_amount
                        this.totalAssuranceEmployeeSalary += element.assurance_amount
                        this.totalVpsSalary += element.vps_amount
                        this.totalItsSalary += element.its_amount
                        this.totalYlomiFeesSalary += element.ylomi_direct_fees
                        this.totalAdvanceSalary += element.salary_advance_amount != null ? element.salary_advance_amount : 0
                        this.totalNetAfterAdvanceSalary = this.totalSalaireNet - this.totalAdvanceSalary
                        this.totalAmountSalary += element.total_amount_to_paid
                        console.log(this.totalSalaireNet, "salaire net des eployés")
                    })
                    console.log(this.clickedDetailPackage, "detailssss")
                    console.log("DETAILS SALAIRES", this.salaryDetails);
                    this.isPaymentSalaryDetails = false;
                    this.$bvModal.hide("modal-action-without-cnss");
                })
                .catch((error) => {
                    console.log(error);
                    this.isPaymentSalaryDetails = false;
                });
        },

        confirmSingularSalaryPaymentWithoutCnss(item, cnss) {
            this.$swal
                .fire({
                    title: "Confirmation",
                    text: "Êtes-vous sûr de vouloir confirmer le paiement?",
                    icon: "warning",
                    showCancelButton: true,
                    showLoaderOnConfirm: true,
                    cancelButtonText: "Non",
                    allowOutsideClick: false,
                    confirmButtonText: "Oui",
                    customClass: {
                        confirmButton: "btn btn-warning",
                        cancelButton: "btn btn-outline-primary ml-1",
                    },

                    buttonsStyling: false,

                    preConfirm: async () => {
                        this.payloadMarkSalaire.payments.push(item.id)
                        await this.markSalaryPaymentAction(this.payloadMarkSalaire)
                            .then((response) => { })
                            .catch((error) => {
                                this.$swal.showValidationMessage(`${error.response.data.message}`);
                            });
                    },
                })
                .then((response) => {
                    console.log(response);
                    /* this.reLoadDataPaymentHistoryBusinessOrdersAction() */
                    /* this.applyLoadMoreBusinessOrderPaymentHistoryAction(
                        this.metaData.current_page_url
                    ); */

                    if (response.value) {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "Succès",
                                icon: "CheckIcon",
                                text: "Paiement salaire validé",
                                variant: "success",
                            },
                        }, {
                            position: "top-center",
                        });
                        this.payloadMarkSalaire = {
                            payments: []
                        }
                        /* this.payementInformations = [] */

                        console.log('status', this.salarySingularInfos)

                        this.salarySingularInfos.status = true

                        /* this.showSalaireWithoutCnssDetails(this.salarySingularInfos, cnss) */


                        if (this.isFiltre == true) {
                            this.applyFilterBusinessSalaryPaymentWithoutCnssAction(cnss)

                        } else {
                            this.showSalaireWithoutCnssDetails(this.salarySingularInfos, cnss);
                        }

                        this.applyGetBusinessSalaryPaymentHistoryAction();
                        this.$bvModal.hide("modal-action-singular-without-cnss");
                        this.applyGetBusinessmployeeSalaryUnpaidAction()
                    }
                })
                .catch((error) => {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Erreur",
                            icon: "AlertTriangleIcon",
                            text: error,
                            variant: "danger",
                        },
                    }, {
                        position: "top-center",
                    });
                    this.payloadMarkSalaire = {
                        payments: []
                    }
                });
        },

        resolveDetailSlaryWithoutCnssStatusVariant: (status) => {
            if (status == true) return "success";

            if (status == false) return "danger";

            return "primary";
        },


        // DETAILS D'UN PAIEMENT DE SALAIRE avec cnss
        showSalaireDetails(salary, cnss) {
            this.isFiltre == false;
            console.log('clickedDetailSalary', salary)
            this.clickedDetailPhoneNumber = salary.phone_number
            this.clickedDetailCustomerName = salary.company_full_name
            this.clickedDetailIfu = salary.company_ifu !== "null" ? salary.company_ifu : ""
            this.clickedDetailPackage = salary
            this.clickedDetailPackage.status = salary.status
            this.isPaymentSalaryDetails = true
            this.paymentPayload.user_id = salary.company_id
            this.paymentPayload.month = salary.month_salary
            this.paymentPayload.year = salary.year
            this.paymentPayload.status = salary.status ? 1 : 0
            this.paymentPayload.cnss = cnss
            this.totalSalaireNet = 0
            this.totalCnssCustomerSalary = 0
            this.totalCnssEmployeeSalary = 0
            this.totalVpsSalary = 0
            this.totalItsSalary = 0
            this.totalYlomiFeesSalary = 0
            this.totalAdvanceSalary = 0
            this.totalNetAfterAdvanceSalary = 0
            this.totalAmountSalary = 0
            this.getBusinessSalaryPayementDetailsAction(this.paymentPayload)
                .then((response) => {
                    this.salaryDetails = response.data;
                    console.log("DETAILS SALAIRES", this.salaryDetails);
                    this.isPaymentSalaryDetails = false;
                    this.salaryDetails.forEach(element => {
                        this.totalSalaireNet += element.employee_salary_amount
                        this.totalCnssCustomerSalary += element.cnss_customer_amount
                        this.totalCnssEmployeeSalary += element.cnss_employee_amount
                        this.totalVpsSalary += element.vps_amount
                        this.totalItsSalary += element.its_amount
                        this.totalYlomiFeesSalary += element.ylomi_direct_fees
                        this.totalAdvanceSalary += element.salary_advance_amount != null ? element.salary_advance_amount : 0
                        this.totalNetAfterAdvanceSalary = this.totalSalaireNet - this.totalAdvanceSalary
                        this.totalAmountSalary += element.total_amount_to_paid
                        console.log(this.totalSalaireNet, "salaire net des eployés")
                    })

                    this.$bvModal.hide("modal-action-with-cnss");
                })
                .catch((error) => {
                    console.log(error);
                    this.isPaymentSalaryDetails = false;
                });
        },

        resolveDetailSlaryStatusVariant: (status) => {
            if (status == true) return "success";

            if (status == false) return "danger";

            return "primary";
        },

        confirmSingularSalaryPayment(item, cnss) {
            console.log(item.id, "Salary item")
            this.$swal
                .fire({
                    title: "Confirmation",
                    text: "Êtes-vous sûr de vouloir confirmer le paiement?",
                    icon: "warning",
                    showCancelButton: true,
                    showLoaderOnConfirm: true,
                    cancelButtonText: "Non",
                    allowOutsideClick: false,
                    confirmButtonText: "Oui",
                    customClass: {
                        confirmButton: "btn btn-warning",
                        cancelButton: "btn btn-outline-primary ml-1",
                    },

                    buttonsStyling: false,

                    preConfirm: async () => {
                        /* this.confirmSingularSalaryPaymentAction(item.id) */
                        this.payloadMarkSalaire.payments.push(item.id)
                        console.log(this.payloadMarkSalaire.payments)
                        await this.markSalaryPaymentAction(this.payloadMarkSalaire)
                            .then((response) => { })
                            .catch((error) => {
                                this.$swal.showValidationMessage(`${error.response.data.message}`);
                            });
                    },
                })
                .then((response) => {
                    console.log(response);

                    if (response.value) {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "Succès",
                                icon: "CheckIcon",
                                text: "Paiement salaire validé",
                                variant: "success",
                            },
                        }, {
                            position: "top-center",
                        });
                        this.payloadMarkSalaire = {
                            payments: []
                        }

                        this.salarySingularInfos.status = true
                        console.log('status', this.salarySingularInfos)

                        if (this.isFiltre == true) {
                            this.applyFilterBusinessSalaryPaymentAction(cnss)

                        } else {
                            this.showSalaireDetails(this.salarySingularInfos, cnss);
                        }
                        this.applyGetBusinessmployeeSalaryUnpaidAction()
                        this.$bvModal.hide("modal-action-singular-with-cnss");
                        this.applyGetBusinessSalaryPaymentHistoryAction();
                    }
                })
                .catch((error) => {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Erreur",
                            icon: "AlertTriangleIcon",
                            text: error,
                            variant: "danger",
                        },
                    }, {
                        position: "top-center",
                    });
                    this.payloadMarkSalaire = {
                        payments: []
                    }
                });
        },

        applyFilterCnssAction(val) {
            this.isFiltreCnssLoading = false
            const data = {
                start_date: val.split('to')[0],
                end_date: val.split('to')[1],
            }
            this.$bvModal.show('modal-filtre-cnss')
            this.isFilterCnss = true
            this.filterCnssAction(data)
                .then(response => {
                    this.isFilterCnss = false
                    this.filtreCnss = response.data
                    this.total = this.filtreCnss.length

                    response.data.forEach((element) => {
                        this.totalCnssCustomer += parseInt(element.cnss_customer_amount),
                            this.totalCnssEmployee += parseInt(element.cnss_employee_amount),
                            this.totalVps += parseInt(element.vps_amount),
                            this.totalIts += parseInt(element.its_amount)
                    })
                    this.totalCnss = parseInt(this.totalCnssCustomer) + parseInt(this.totalCnssEmployee) + parseInt(this.totalVps) + parseInt(this.totalIts)
                    this.$bvModal.show('modal-cnss-filtre')
                }).catch(error => {
                    this.isFilterCnss = false
                    if (error.response.status !== 422) {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Erreur',
                                icon: 'AlertTriangleIcon',
                                text: 'Erreur lors du filtrage, Réessayer!',
                                variant: 'danger',
                            },
                        }, {
                            position: 'top-center',
                        },)
                    } else {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Erreur',
                                icon: 'AlertTriangleIcon',
                                text: error.response.data.message,
                                variant: 'danger',
                            },
                        }, {
                            position: 'top-center',
                        },)
                    }
                })
        },

        netApayer(montant, avance) {
            const net = avance == null ? montant : (montant - avance)
            return net
        },
        net_salary(salaire, avanceSalaire) {
            const net = salaire == null ? avanceSalaire : (salaire - avanceSalaire)
            return net
        },
        montantApayer(salary, avanceSalary) {
            const montant = avanceSalary == null ? salary : (salary - avanceSalary)
            return montant
        },


        applyConfirmSalaryCumulAction(item, cnss) {
            (this.paymentCumulPayload.month_salary = item.month_salary),
                (this.paymentCumulPayload.cnss = cnss),
                (this.paymentCumulPayload.year = item.year);

            this.$swal
                .fire({
                    title: "Confirmation",
                    text: "Êtes-vous sûr de vouloir confirmer le paiement du cumul?",
                    icon: "warning",
                    showCancelButton: true,
                    showLoaderOnConfirm: true,
                    cancelButtonText: "Non",
                    allowOutsideClick: false,
                    confirmButtonText: "Oui",
                    customClass: {
                        confirmButton: "btn btn-warning",
                        cancelButton: "btn btn-outline-primary ml-1",
                    },

                    buttonsStyling: false,

                    preConfirm: async () => {
                        await this.confirmSalaryCumulPaymentAction({
                            packageId: item.package_id,
                            payload: this.paymentCumulPayload,
                        })
                            .then((response) => { })
                            .catch((error) => {
                                this.$swal.showValidationMessage(`${error.response.data.message}`);
                            });
                    },
                })
                .then((response) => {
                    console.log(response);
                    this.applyLoadMoreBusinessOrderPaymentHistoryAction(
                        this.metaData.current_page_url
                    );

                    if (response.value) {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "Succès",
                                icon: "CheckIcon",
                                text: "Paiement salaire validé",
                                variant: "success",
                            },
                        }, {
                            position: "top-center",
                        });
                        this.$bvModal.hide("modal-action-with-cnss");
                        this.applyGetBusinessSalaryPaymentHistoryAction();
                    }
                })
                .catch((error) => {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Erreur",
                            icon: "AlertTriangleIcon",
                            text: error,
                            variant: "danger",
                        },
                    }, {
                        position: "top-center",
                    });
                });
        },
        applyCancelCumulPaymentAction(item, cnss) {
            (this.cumulCancelPayload.month_salary = item.month_salary),
                (this.cumulCancelPayload.cnss = cnss),
                (this.cumulCancelPayload.year = item.year);
            this.$swal
                .fire({
                    title: "Annuler",
                    text: "Êtes-vous sûr de vouloir annuler cet paiement?",
                    icon: "warning",
                    showCancelButton: true,
                    showLoaderOnConfirm: true,
                    cancelButtonText: "Non",
                    allowOutsideClick: false,
                    confirmButtonText: "Oui",
                    customClass: {
                        confirmButton: "btn btn-warning",
                        cancelButton: "btn btn-outline-primary ml-1",
                    },
                    buttonsStyling: false,
                    preConfirm: async () => {
                        await this.cancelCumulPaymentAction({
                            packageId: item.package_id,
                            payload: this.cumulCancelPayload,
                        })
                            .then((response) => { })
                            .catch((error) => {
                                this.$swal.showValidationMessage(`${error.response.data.message}`);
                            });
                        this.$bvModal.hide("modal-action-with-cnss");
                        this.applyGetBusinessSalaryPaymentHistoryAction();

                        this.applyLoadMoreBusinessOrderPaymentHistoryAction(
                            this.metaData.current_page_url
                        );
                    },
                })

                .then((response) => {
                    if (response.value) {
                        this.applyLoadMoreBusinessOrderPaymentHistoryAction(
                            this.metaData.current_page_url
                        );
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "Succès",
                                icon: "CheckIcon",
                                text: "Paiement annulé",
                                variant: "success",
                            },
                        }, {
                            position: "top-center",
                        });
                        this.applyGetBusinessSalaryPaymentHistoryAction();
                    }
                })
                .catch((error) => {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Erreur",
                            icon: "AlertTriangleIcon",
                            text: error,
                            variant: "danger",
                        },
                    }, {
                        position: "top-center",
                    });
                });
        },


        cancelSingularSalaryPayment(item, cnss) {
            console.log(item.id)
            this.$swal
                .fire({
                    title: "Annuler",
                    text: "Êtes-vous sûr de vouloir annuler cet paiement?",
                    icon: "warning",
                    showCancelButton: true,
                    showLoaderOnConfirm: true,
                    cancelButtonText: "Non",
                    allowOutsideClick: false,
                    confirmButtonText: "Oui",
                    customClass: {
                        confirmButton: "btn btn-warning",
                        cancelButton: "btn btn-outline-primary ml-1",
                    },
                    buttonsStyling: false,
                    preConfirm: async () => {
                        await this.cancelSingularSalaryPaymentAction(item.id)
                            .then((response) => { })
                            .catch((error) => {
                                this.$swal.showValidationMessage(`${error.response.data.message}`);
                            });
                        this.reLoadDataPaymentHistoryBusinessOrdersAction()
                        this.applyLoadMoreBusinessOrderPaymentHistoryAction(
                            this.metaData.current_page_url
                        );
                    },
                })

                .then((response) => {
                    if (response.value) {
                        this.applyLoadMoreBusinessOrderPaymentHistoryAction(
                            this.metaData.current_page_url
                        );
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "Succès",
                                icon: "CheckIcon",
                                text: "Paiement annulé",
                                variant: "success",
                            },
                        }, {
                            position: "top-center",
                        });
                        this.clickedDetailPackage.status = true
                        console.log('status', this.clickedDetailPackage)
                        if (this.isFiltre == true) {
                            this.applyFilterBusinessSalaryPaymentAction(cnss)

                        } else {
                            this.showSalaireDetails(this.clickedDetailPackage, cnss);
                        }
                        this.applyGetBusinessSalaryPaymentHistoryAction();
                        this.$bvModal.hide("modal-action-singular-with-cnss");
                    }
                })
                .catch((error) => {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Erreur",
                            icon: "AlertTriangleIcon",
                            text: error,
                            variant: "danger",
                        },
                    }, {
                        position: "top-center",
                    });
                });
        },
        blockSingularSalaryPayment(item, cnss) {
            console.log(item.id)
            this.$swal({
                title: item.auto_send == true ? "Bloqué le salaire" : "Débloqué le salaire",
                text: item.auto_send == true ?
                    "Êtes-vous sûr de vouloir bloquer  le paiement du salaire de l'employé ?" :
                    "Êtes-vous sûr de vouloir débloquer  le paiement du salaire de l'employé ?",
                icon: "warning",
                showCancelButton: true,
                showLoaderOnConfirm: true,
                cancelButtonText: "Non",
                allowOutsideClick: false,
                confirmButtonText: "Oui",
                customClass: {
                    confirmButton: "btn btn-warning",
                    cancelButton: "btn btn-outline-primary ml-1",
                },
                buttonsStyling: false,
                preConfirm: async () => {

                    if (item.auto_send == true) {
                        const payload = {
                            auto_send: false,
                        };
                        return await this.blockSalaryPaiementAction({
                            id: item.id
                        });

                    }

                    const payload = {
                        auto_send: true,
                    };
                    await this.unBlockSalaryPaiementAction({
                        id: item.id
                    });
                },
            })
                .then((response) => {
                    this.applyLoadMoreBusinessOrderPaymentHistoryAction(
                        this.metaData.current_page_url
                    );
                    if (response.isConfirmed) {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "Succès",
                                icon: "CheckIcon",
                                text: item.auto_send == true ?
                                    "Paiement du salaire de l'employé bloqué avec succès" :
                                    "Paiement du salaire de l'employé débloqué avec succès",
                                variant: "success",
                            },
                        }, {
                            position: "top-center",
                        });
                        console.log('status', this.clickedDetailPackage)
                        if (this.isFiltre == true) {
                            this.applyFilterBusinessSalaryPaymentAction(cnss)

                        } else {
                            this.showSalaireDetails(this.clickedDetailPackage, cnss);
                        }
                        this.applyGetBusinessSalaryPaymentHistoryAction();
                        this.$bvModal.hide("modal-action-singular-with-cnss");
                    }
                })
                .catch((error) => {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Erreur",
                            icon: "AlertTriangleIcon",
                            text: error,
                            variant: "danger",
                        },
                    }, {
                        position: "top-center",
                    });
                });
        },
        applyAdvanceSingularPaymentAction() {
            this.$refs.advancePaymentMonth.validate().then((success) => {
                if (success) {
                    this.isAdvancePaymentLoading = true;
                    this.advancePaymentAction({
                        payload: this.payload,
                        employeeId: this.lineItemEmployeeId,
                    })
                        .then((response) => {
                            this.applyLoadMoreBusinessOrderPaymentHistoryAction(
                                this.metaData.current_page_url
                            );

                            this.isAdvancePaymentLoading = false;
                            // this.applyGetOfferType()
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: "Succès",
                                    icon: "CheckIcon",
                                    text: response.message,
                                    variant: "success",
                                },
                            }, {
                                position: "top-center",
                            });
                            // this.lineItemDirectOrderId = null,
                            // this.lineItemEmployeeId = null,
                            this.$bvModal.hide("modal-payment-advance-month");
                            this.payload = {
                                month: "",
                                salary_advance_amount: "",
                            };
                            this.clickedDetailPackage.salary_advance_amount == salary_advance_amount
                            console.log('status', this.clickedDetailPackage)
                            if (this.isFiltre == true) {
                                this.applyFilterBusinessSalaryPaymentAction(cnss)

                            } else {
                                this.showSalaireDetails(this.clickedDetailPackage, cnss);
                            }
                            this.applyGetBusinessSalaryPaymentHistoryAction();
                            this.$bvModal.hide("modal-action-singular-with-cnss");
                        })
                        .catch((error) => {
                            // this.lineItemDirectOrderId = null,
                            // this.lineItemEmployeeId = null,
                            console.log(error);
                            this.isAdvancePaymentLoading = false;
                        });
                }
            });
        },
        showAdvanceSingularPayementMonthModal(item) {
            this.clickAdvanceAmount = item.salary_advance_amount
            this.clickNetSalary = item.employee_salary_amount
            console.log(this.clickNetSalary)
            this.lineItemEmployeeId = item.employee_id;
            this.payload.month = item.month_salary;
            this.$bvModal.show("modal-payment-advance-month");
        },
        hideModalPaymentAdvanceMonthForm() {
            this.lineItemDirectOrderId = null;
            this.lineItemEmployeeId = null;
            this.$bvModal.hide("modal-payment-advance-month");
            this.payload = {
                month: "",
                salary_advance_amount: "",
            };
        },


        applyConfirmSalaryCumulWithoutCnssAction(item, cnss) {
            (this.paymentCumulPayload.month_salary = item.month_salary),
                (this.paymentCumulPayload.cnss = cnss),
                (this.paymentCumulPayload.year = item.year);

            this.$swal
                .fire({
                    title: "Confirmation",
                    text: "Êtes-vous sûr de vouloir confirmer le paiement du cumul?",
                    icon: "warning",
                    showCancelButton: true,
                    showLoaderOnConfirm: true,
                    cancelButtonText: "Non",
                    allowOutsideClick: false,
                    confirmButtonText: "Oui",
                    customClass: {
                        confirmButton: "btn btn-warning",
                        cancelButton: "btn btn-outline-primary ml-1",
                    },

                    buttonsStyling: false,

                    preConfirm: async () => {
                        await this.confirmSalaryCumulPaymentAction({
                            packageId: item.package_id,
                            payload: this.paymentCumulPayload,
                        })
                            .then((response) => { })
                            .catch((error) => {
                                this.$swal.showValidationMessage(`${error.response.data.message}`);
                            });
                    },
                })
                .then((response) => {
                    console.log(response);
                    this.applyLoadMoreBusinessOrderPaymentHistoryAction(
                        this.metaData.current_page_url
                    );

                    if (response.value) {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "Succès",
                                icon: "CheckIcon",
                                text: "Paiement salaire validé",
                                variant: "success",
                            },
                        }, {
                            position: "top-center",
                        });
                        this.$bvModal.hide("modal-action-without-cnss");
                        this.applyGetBusinessSalaryPaymentHistoryAction();;
                    }
                })
                .catch((error) => {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Erreur",
                            icon: "AlertTriangleIcon",
                            text: error,
                            variant: "danger",
                        },
                    }, {
                        position: "top-center",
                    });
                });
        },
        applyCancelCumulPaymentWithoutCnssAction(item, cnss) {
            (this.cumulCancelPayload.month_salary = item.month_salary),
                (this.cumulCancelPayload.cnss = cnss),
                (this.cumulCancelPayload.year = item.year);
            this.$swal
                .fire({
                    title: "Annuler",
                    text: "Êtes-vous sûr de vouloir annuler cet paiement?",
                    icon: "warning",
                    showCancelButton: true,
                    showLoaderOnConfirm: true,
                    cancelButtonText: "Non",
                    allowOutsideClick: false,
                    confirmButtonText: "Oui",
                    customClass: {
                        confirmButton: "btn btn-warning",
                        cancelButton: "btn btn-outline-primary ml-1",
                    },
                    buttonsStyling: false,
                    preConfirm: async () => {
                        await this.cancelCumulPaymentAction({
                            packageId: item.package_id,
                            payload: this.cumulCancelPayload,
                        })
                            .then((response) => { })
                            .catch((error) => {
                                this.$swal.showValidationMessage(`${error.response.data.message}`);
                            });

                        this.applyLoadMoreBusinessOrderPaymentHistoryAction(
                            this.metaData.current_page_url
                        );
                    },
                })

                .then((response) => {
                    if (response.value) {
                        this.applyLoadMoreBusinessOrderPaymentHistoryAction(
                            this.metaData.current_page_url
                        );
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "Succès",
                                icon: "CheckIcon",
                                text: "Paiement annulé",
                                variant: "success",
                            },
                        }, {
                            position: "top-center",
                        });
                        this.$bvModal.hide("modal-action-without-cnss");
                        this.applyGetBusinessSalaryPaymentHistoryAction();
                    }
                })
                .catch((error) => {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Erreur",
                            icon: "AlertTriangleIcon",
                            text: error,
                            variant: "danger",
                        },
                    }, {
                        position: "top-center",
                    });
                });
        },


        cancelSingularSalaryPaymentWithoutCnss(item, cnss) {
            this.$swal
                .fire({
                    title: "Annuler",
                    text: "Êtes-vous sûr de vouloir annuler cet paiement?",
                    icon: "warning",
                    showCancelButton: true,
                    showLoaderOnConfirm: true,
                    cancelButtonText: "Non",
                    allowOutsideClick: false,
                    confirmButtonText: "Oui",
                    customClass: {
                        confirmButton: "btn btn-warning",
                        cancelButton: "btn btn-outline-primary ml-1",
                    },
                    buttonsStyling: false,
                    preConfirm: async () => {
                        await this.cancelSingularSalaryPaymentAction(item.id)
                            .then((response) => { })
                            .catch((error) => {
                                this.$swal.showValidationMessage(`${error.response.data.message}`);
                            });

                        this.applyLoadMoreBusinessOrderPaymentHistoryAction(
                            this.metaData.current_page_url
                        );
                    },
                })

                .then((response) => {
                    if (response.value) {
                        this.applyLoadMoreBusinessOrderPaymentHistoryAction(
                            this.metaData.current_page_url
                        );
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "Succès",
                                icon: "CheckIcon",
                                text: "Paiement annulé",
                                variant: "success",
                            },
                        }, {
                            position: "top-center",
                        });
                        this.clickedDetailPackage.status = true
                        console.log('status', this.clickedDetailPackage)

                        if (this.isFiltre == true) {
                            this.applyFilterBusinessSalaryPaymentWithoutCnssAction(cnss)

                        } else {
                            this.showSalaireWithoutCnssDetails(this.clickedDetailPackage, cnss);
                        }
                        this.applyGetBusinessSalaryPaymentHistoryAction();
                        this.$bvModal.hide("modal-action-singular-without-cnss");
                    }
                })
                .catch((error) => {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Erreur",
                            icon: "AlertTriangleIcon",
                            text: error,
                            variant: "danger",
                        },
                    }, {
                        position: "top-center",
                    });
                });
        },
        blockSingularSalaryPaymentWithoutCnss(item, cnss) {
            console.log(item.id)
            this.$swal({
                title: item.auto_send == true ? "Bloqué le salaire" : "Débloqué le salaire",
                text: item.auto_send == true ?
                    "Êtes-vous sûr de vouloir bloquer  le paiement du salaire de l'employé ?" :
                    "Êtes-vous sûr de vouloir débloquer  le paiement du salaire de l'employé ?",
                icon: "warning",
                showCancelButton: true,
                showLoaderOnConfirm: true,
                cancelButtonText: "Non",
                allowOutsideClick: false,
                confirmButtonText: "Oui",
                customClass: {
                    confirmButton: "btn btn-warning",
                    cancelButton: "btn btn-outline-primary ml-1",
                },
                buttonsStyling: false,
                preConfirm: async () => {

                    if (item.auto_send == true) {
                        const payload = {
                            auto_send: false,
                        };
                        return await this.blockSalaryPaiementAction({
                            id: item.id
                        });

                    }

                    const payload = {
                        auto_send: true,
                    };
                    await this.unBlockSalaryPaiementAction({
                        id: item.id
                    });
                },
            })
                .then((response) => {
                    this.applyLoadMoreBusinessOrderPaymentHistoryAction(
                        this.metaData.current_page_url
                    );
                    if (response.isConfirmed) {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "Succès",
                                icon: "CheckIcon",
                                text: item.auto_send == true ?
                                    "Paiement du salaire de l'employé bloqué avec succès" :
                                    "Paiement du salaire de l'employé débloqué avec succès",
                                variant: "success",
                            },
                        }, {
                            position: "top-center",
                        });

                        console.log('status', this.salarySingularInfos)
                        if (this.isFiltre == true) {
                            this.applyFilterBusinessSalaryPaymentWithoutCnssAction(cnss)

                        } else {
                            this.showSalaireWithoutCnssDetails(this.clickedDetailPackage, cnss);
                        }

                        this.applyGetBusinessSalaryPaymentHistoryAction();
                        this.$bvModal.hide("modal-action-singular-without-cnss");
                    }
                })
                .catch((error) => {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Erreur",
                            icon: "AlertTriangleIcon",
                            text: error,
                            variant: "danger",
                        },
                    }, {
                        position: "top-center",
                    });
                });
        },

        showAdvanceSingularPayementMonthModalWithoutCnss(item) {
            this.clickAdvanceAmount = item.salary_advance_amount
            this.clickNetSalary = item.employee_salary_amount
            console.log(this.clickNetSalary)
            this.lineItemEmployeeId = item.employee_id;
            this.payload.month = item.month_salary;
            this.$bvModal.show("modal-payment-advance-month-without-cnss");
        },
        hideModalPaymentAdvanceMonthFormWithoutCnss() {
            this.lineItemDirectOrderId = null;
            this.lineItemEmployeeId = null;
            this.$bvModal.hide("modal-payment-advance-month-without-cnss");
            this.payload = {
                month: "",
                salary_advance_amount: "",
            };

        },

        // FIN PAYMENT SALARY WITHOUT CNSS
        applyBlockSingularSalaryPaiementAction(item) {
            this.$swal({
                title: item.auto_send == true ? "Bloqué le salaire" : "Débloqué le salaire",
                text: item.auto_send == true ?
                    "Êtes-vous sûr de vouloir bloquer  le paiement du salaire de l'employé ?" :
                    "Êtes-vous sûr de vouloir débloquer  le paiement du salaire de l'employé ?",
                icon: "warning",
                showCancelButton: true,
                showLoaderOnConfirm: true,
                cancelButtonText: "Non",
                allowOutsideClick: false,
                confirmButtonText: "Oui",
                customClass: {
                    confirmButton: "btn btn-warning",
                    cancelButton: "btn btn-outline-primary ml-1",
                },
                buttonsStyling: false,
                preConfirm: async () => {
                    if (item.auto_send == true) {
                        const payload = {
                            auto_send: false,
                        };
                        return await this.blockSalaryPaiementAction({
                            id: item.package_id,
                            payload,
                        });
                    }

                    const payload = {
                        auto_send: true,
                    };
                    await this.blockSalaryPaiementAction({
                        id: item.id,
                        payload,
                    });
                },
            })
                .then((response) => {
                    this.applyLoadMoreBusinessOrderPaymentHistoryAction(
                        this.metaData.current_page_url
                    );
                    if (response.isConfirmed) {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "Succès",
                                icon: "CheckIcon",
                                text: item.auto_send == true ?
                                    "Paiement du salaire de l'employé bloqué avec succès" :
                                    "Paiement du salaire de l'employé débloqué avec succès",
                                variant: "success",
                            },
                        }, {
                            position: "top-center",
                        });
                    }
                })
                .catch((error) => {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Erreur",
                            icon: "AlertTriangleIcon",
                            text: error,
                            variant: "danger",
                        },
                    }, {
                        position: "top-center",
                    });
                });
        },

        applyConfirmSalaryPlacementAction(item) {
            this.$swal({
                title: "Confirmation",
                text: "Êtes-vous sûr de vouloir confirmer le paiement de la prestation ?",
                icon: "warning",
                showCancelButton: true,
                showLoaderOnConfirm: true,
                cancelButtonText: "Non",
                allowOutsideClick: false,
                confirmButtonText: "Oui",
                customClass: {
                    confirmButton: "btn btn-warning",
                    cancelButton: "btn btn-outline-primary ml-1",
                },
                buttonsStyling: false,
                preConfirm: async () => {
                    await this.confirmSalaryPaiementAction({
                        id: item.direct_order_id,
                        directSalaryPaiementId: item.id,
                        paymentMethod: "3",
                    });
                },
            })
                .then((response) => {
                    this.applyLoadMoreBusinessOrderPaymentHistoryAction(
                        this.metaData.current_page_url
                    );

                    if (response.value) {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "Succès",
                                icon: "CheckIcon",
                                text: "Paiement salaire validé",
                                variant: "success",
                            },
                        }, {
                            position: "top-center",
                        });
                    }
                })
                .catch((error) => {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Erreur",
                            icon: "AlertTriangleIcon",
                            text: error,
                            variant: "danger",
                        },
                    }, {
                        position: "top-center",
                    });
                });
        },

        applyFilterBusinessSalaryPaymentAction(cnss) {
            const allIsNUl = Object.values(this.payloadFilter).every((el) => el === "");
            if (allIsNUl) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Warning",
                        icon: "CheckIcon",
                        text: "Veuillez sélectionner au moins un champ du filtre.",
                        variant: "warning",
                    },
                }, {
                    position: "top-center",
                });
            } else {
                this.isFilterDirectSalaryPayment = true;
                this.isPaymentSalaryDetails = true;
                this.filtreSalaryDetails = [];
                // this.payloadFilter.type = "CL";
                if (cnss = 1) {
                    this.payloadFilter.cnss = 1
                } else {
                    this.payloadFilter.cnss = 0
                }
                this.filterBusinessOrdersSalaryPaymentsAction(this.payloadFilter)
                    .then((response) => {
                        this.isFilterDirectSalaryPayment = false;
                        this.isPaymentSalaryDetails = false;
                        this.filtreSalaryDetails = this.customTab(
                            this.getBusinessOrdersPaymentHistory.withCnss
                        );
                        this.total = this.filtreSalaryDetails.length;
                    })
                    .catch((error) => {
                        this.isFilterDirectSalaryPayment = false;
                        this.isPaymentSalaryDetails = false;
                        console.log(error);
                    });
            }
        },

        applyFilterBusinessSalaryPaymentWithoutCnssAction(cnss) {
            this.isFiltre = true;
            console.log(this.salarySingularInfos, "clic")
            const allIsNUl = Object.values(this.payloadFilter).every((el) => el === "");
            if (allIsNUl) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Warning",
                        icon: "CheckIcon",
                        text: "Veuillez sélectionner au moins un champ du filtre.",
                        variant: "warning",
                    },
                }, {
                    position: "top-center",
                });
            } else {
                this.isFilterDirectSalaryPayment = true;
                this.isPaymentSalaryDetails = true;
                this.filtreSalaryDetailsWithoutCnss = [];
                // this.payloadFilter.type = "CL";
                this.payloadFilter.cnss = 0

                this.filterBusinessOrdersSalaryPaymentsAction(this.payloadFilter)
                    .then((response) => {
                        this.isFilterDirectSalaryPayment = false;
                        this.isPaymentSalaryDetails = false;
                        this.filtreSalaryDetailsWithoutCnss = this.customTab(
                            response.data.withoutCnss
                        );
                        console.log(this.filtreSalaryDetailsWithoutCnss, "Filter sans cnss")
                        this.totalWithoutCnss = this.filtreSalaryDetailsWithoutCnss.length;
                    })
                    .catch((error) => {
                        this.isFilterDirectSalaryPayment = false;
                        this.isPaymentSalaryDetails = false;
                        console.log(error);
                    });
            }
        },
        customTab(array) {
            const tab = [];
            array.forEach((element) => {
                switch (element.status) {
                    case false:
                        element.setStatus = "Non Payé";
                        break;
                    case true:
                        element.setStatus = "Payé";
                        break;
                    default:
                        element.setStatus = null;
                }
                tab.push(element);
            });
            return tab;
        },

        // payement function
        /*  applyEmployeePayementFile() {
             this.$refs.downloadFile.validate().then((success) => {
                 if (success) {
                     this.isPaymentLoading = true;
                     $http
                         .post("/admin/direct/employees/payement/file", this.payement, {
                             responseType: "blob",
                         })
                         .then((response) => {
                             this.isPaymentLoading = false;
                             const url = window.URL.createObjectURL(new Blob([response.data]));
                             const link = document.createElement("a");
                             link.href = url;
                             link.setAttribute(
                                 "download",
                                 `bulk-${this.payement.month}-${new Date().getFullYear()}.csv`
                             );
                             document.body.appendChild(link);
                             link.click();
                             this.$toast({
                                 component: ToastificationContent,
                                 props: {
                                     title: "Succès",
                                     icon: "CheckIcon",
                                     text: "Fichier téléchargé avec succès",
                                     variant: "success",
                                 },
                             }, {
                                 position: "top-center",
                             });
                             this.hideModalPaymentFileModal();
                         })
                         .catch((error) => {
                             if (error.response.status == 422) {
                                 this.$toast({
                                     component: ToastificationContent,
                                     props: {
                                         title: "Notification",
                                         icon: "CheckIcon",
                                         text: "Aucun employé à payé pour ce mois",
                                         variant: "warning",
                                     },
                                 }, {
                                     position: "top-center",
                                 });
                                 this.hideModalPaymentFileModal();
                             }
                             this.isPaymentLoading = false;
                         });
                 }
             });
         },
  */
        applyGetBusinessSalaryPaymentHistoryAction() {
            this.$store.commit("SET_IS_BUSINESS_PAYMENT_HISTORY_LOADING", true);
            this.getBusinessOrdersSalaryPaymentsAction()
                .then((response) => {
                    console.log("Salaire business", response)
                    this.$store.commit("SET_IS_BUSINESS_PAYMENT_HISTORY_LOADING", false);
                    this.rowsWithCnss = this.customTab(response.data.withCnss.data);
                    this.rowsWithoutCnss = this.customTab(response.data.withoutCnss.data);
                    console.log(this.rowsWithCnss);
                })
                .catch((error) => {
                    this.$store.commit("SET_IS_BUSINESS_PAYMENT_HISTORY_LOADING", false);
                    console.log("ERROR", error);
                });
        },
        reLoadDataPaymentHistoryBusinessOrdersAction() {
            this.payloadFilter.user_id = "";
            this.payloadFilter.month_salary = "";
            this.payloadFilter.employee_received_salary_advance = "";
            this.payloadFilter.auto_send = "";
            this.payloadFilter.year = "";
            this.payloadFilter.status = "";
            this.payloadFilter.cnss = "";
            this.payloadFilter.ca_id = "";
            this.start_date = "";
            this.end_date = "";
            this.isReLoadDataPaymentHistoryDirectOrders = true;
            this.$store.commit("SET_IS_BUSINESS_PAYMENT_HISTORY_LOADING", true);
            this.rowsWithCnss = [];
            this.rowsWithoutCnss = [];
            this.getBusinessOrdersSalaryPaymentsAction()
                .then((response) => {
                    this.isReLoadDataPaymentHistoryDirectOrders = false;
                    this.$store.commit("SET_IS_BUSINESS_PAYMENT_HISTORY_LOADING", false);
                })
                .catch((error) => {
                    this.isReLoadDataPaymentHistoryDirectOrders = false;
                    this.$store.commit("SET_IS_BUSINESS_PAYMENT_HISTORY_LOADING", false);
                });
        },

        applyLoadMoreBusinessOrderPaymentHistoryAction(url) {
            this.$store.commit("SET_IS_BUSINESS_PAYMENT_HISTORY_LOADING", true);
            this.rowsWithCnss = [];
            this.rowsWithoutCnss = [];
            this.loadMoreBusinessOrderPaymentHistoryAction(url)
                .then(() => {
                    this.$store.commit("SET_IS_BUSINESS_PAYMENT_HISTORY_LOADING", false);
                })
                .catch(() => {
                    this.$store.commit("SET_IS_BUSINESS_PAYMENT_HISTORY_LOADING", false);
                });
        },

        applyAdvancePaymentAction(cnss) {
            this.isAdvancePaymentLoading = true;
            const netSalary = this.net_salary(this.clickNetSalary, this.clickAdvanceAmount)
            console.log('NET SALARY', netSalary)
            if (this.payload.salary_advance_amount >= netSalary) {
                this.isAdvancePaymentLoading = false;
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Erreur",
                        icon: "AlertTriangleIcon",
                        text: "Le montant entré est supérieur au salaire à prendre par l'employé",
                        variant: "danger",
                    },
                }, {
                    position: "top-center",
                });
                this.isAdvancePaymentLoading = false;
                this.$bvModal.hide("modal-payment-advance-month");
                this.payload = {
                    month: "",
                    salary_advance_amount: "",
                };
            } else {
                this.$refs.advancePaymentMonth.validate().then((success) => {
                    if (success) {
                        this.payload.type = "business"
                        this.isAdvancePaymentLoading = true;
                        this.advancePaymentAction({
                            payload: this.payload,
                            directOrderId: this.lineItemDirectOrderId,
                            employeeId: this.lineItemEmployeeId,
                        })
                            .then((response) => {
                                this.applyLoadMoreBusinessOrderPaymentHistoryAction(
                                    this.metaData.current_page_url
                                );

                                this.isAdvancePaymentLoading = false;
                                // this.applyGetOfferType()
                                this.$toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: "Succès",
                                        icon: "CheckIcon",
                                        text: response.message,
                                        variant: "success",
                                    },
                                }, {
                                    position: "top-center",
                                });
                                // this.lineItemDirectOrderId = null,
                                // this.lineItemEmployeeId = null,
                                if (cnss == 0) {
                                    this.hideModalPaymentAdvanceMonthFormWithoutCnss()
                                    this.$bvModal.hide("modal-action-singular-without-cnss");
                                } else {
                                    this.hideModalPaymentAdvanceMonthForm()
                                    this.$bvModal.hide("modal-action-singular-with-cnss");
                                }

                                this.clickedDetailPackage.salary_advance_amount == this.clickAdvanceAmount
                                console.log('status', this.clickedDetailPackage)
                                if (this.isFiltre == true) {
                                    this.applyFilterBusinessSalaryPaymentAction(cnss)

                                } else {
                                    this.showSalaireDetails(this.clickedDetailPackage, cnss);
                                }
                                this.applyGetBusinessSalaryPaymentHistoryAction();
                            })
                            .catch((error) => {
                                // this.lineItemDirectOrderId = null,
                                // this.lineItemEmployeeId = null,
                                console.log(error);
                                this.isAdvancePaymentLoading = false;
                                this.$toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: "Erreur",
                                        icon: "AlertTriangleIcon",
                                        text: error.response.data.message,
                                        variant: "danger",
                                    },
                                }, {
                                    position: "top-center",
                                });
                                if (cnss == 0) {
                                    this.hideModalPaymentAdvanceMonthFormWithoutCnss()
                                } else {
                                    this.hideModalPaymentAdvanceMonthForm()
                                }
                            });

                    }

                });
            }
        },

        hideModalPaymentFileModal() {
            this.$bvModal.hide("modal-new-payement-operation");
            this.isPaymentLoading = false;
            this.payement = {
            };
        },

        // hideModalPaymentAdvanceMonthForm() {
        //   this.lineItemDirectOrderId = null;
        //   this.lineItemEmployeeId = null;
        //   this.$bvModal.hide("modal-payment-advance-month");
        // },

        showAdvancePayementMonthModal(item) {
            this.lineItemDirectOrderId = item.direct_order_id;
            this.lineItemEmployeeId = item.employee_id;
            this.payload.month = item.month_salary;
            this.$bvModal.show("modal-payment-advance-month");
        },

        showEmployeeWalletDetails(walletId) {
            console.log(walletId);
            this.$router.push({
                name: "admin-employees-wallet",
                params: {
                    id: walletId
                },
            });
        },

        showPayement() {
            this.$bvModal.show("modal-new-payement-operation");
        },

        clikedWithoutCnss(data, cnss) {

            this.paymentLink = `Bonjour Mr/Mme!*${data.customer_full_name}*, Ylomi vous remercie pour votre confiance et votre fidélité. Merci de cliquer sur le lien ci-dessous pour effectuer le paiement de nos prestations de vos employés: \n *https://ylomi.net/direct/${data.package_id}/salary/payments?month=${data.month_salary}&year=${data.year}&cnss=${cnss}&montant=${data.total_amount_to_be_paid}&avance=${data.total_salary_advance_amount}&ylomi_fees=${data.total_ylomi_direct_fees}*`
            this.generate = []
            this.generate.push({
                paymentLink: this.paymentLink
            })

        },
        clikedWithCnss(data, cnss) {

            this.paymentLink = `Bonjour Mr/Mme!*${data.customer_full_name}*, Ylomi vous remercie pour votre confiance et votre fidélité. Merci de cliquer sur le lien ci-dessous pour effectuer le paiement de nos prestations de vos employés: \n *https://ylomi.net/direct/${data.package_id}/salary/payments?month=${data.month_salary}&year=${data.year}&cnss=${cnss}&montant=${data.total_amount_to_be_paid}&avance=${data.total_salary_advance_amount}&ylomi_fees=${data.total_ylomi_direct_fees}*`
            this.generate = []
            this.generate.push({
                paymentLink: this.paymentLink
            })

        },
        clikedSigular(data) {
            this.paymentLink = `Bonjour Mr/Mme!*${this.clickedDetailCustomerName}*, Ylomi vous remercie pour votre confiance et votre fidélité. Merci de cliquer sur le lien ci-dessous pour effectuer le paiement de nos prestations dont l'employé(e) est : ${data.employee_full_name} \n *https://ylomi.net/direct/${data.id}/payment/*`
            this.generate = []
            this.generate.push({
                paymentLink: this.paymentLink
            })
        },

        copyPaymentLink() {
            navigator.clipboard.writeText(this.paymentLink)
                .then(() => {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Succès",
                            icon: "CheckIcon",
                            text: "Lien copié avec succès",
                            variant: "success",
                        },
                    }, {
                        position: "top-center",
                    });
                })
        },

        sendPaiementLinkToCustomer(data) {
            window.open(
                `https://wa.me/${data.phone_number}?text=Bonjour Mr/Mme!*${data.customer_full_name}*, Ylomi vous remercie pour votre confiance et votre fidélité. Merci de cliquer sur le lien ci-dessous pour effectuer le paiement de nos prestations de vos employés: *https://ylomi.net/direct/${data.package_id}/payments/*`
            );
        },

        sendSingularSalaryPaymentLinkWithoutCnss(data) {
            window.open(
                `https://wa.me/${this.clickedDetailPhoneNumber}?text=Bonjour Mr/Mme!*${this.clickedDetailCustomerName}*, Ylomi vous remercie pour votre confiance et votre fidélité. Merci de cliquer sur le lien ci-dessous pour effectuer le paiement de nos prestations dont l'employé(e) est : ${data.employee_full_name} https://ylomi.net/direct/${data.package_id}/payment/${data.id}`
            );
        },

        sendSingularSalaryPaymentLink(data) {
            window.open(
                `https://wa.me/${this.clickedDetailPhoneNumber}?text=Bonjour Mr/Mme!*${this.clickedDetailCustomerName}*, Ylomi vous remercie pour votre confiance et votre fidélité. Merci de cliquer sur le lien ci-dessous pour effectuer le paiement de nos prestations dont l'employé(e) est : ${data.employee_full_name} https://ylomi.net/direct/payment/${data.id}`
            );
        },

        // clickedDetail(data){
        //   this.customerPhone_number = data.customer_phone_number
        // },

        showActionTimesModal(item) {
            this.$bvModal.show("modal-action-times");
            this.currentOrder = item;
        },

        applyGetDirectOrdersAssignedToBusinessManagerAction(id) {
            this.$store.commit("SET_IS_DIRECT_ORDERS_LOADING", true);
            this.getDirectOrdersAssignedToBusinessManagerAction(id)
                .then(() => {
                    this.$store.commit("SET_IS_DIRECT_ORDERS_LOADING", false);
                })
                .catch(() => {
                    this.$store.commit("SET_IS_DIRECT_ORDERS_LOADING", false);
                });
        },

        applyGetDirectOrderAction() {
            this.$store.commit("SET_IS_DIRECT_ORDERS_LOADING", true);

            this.getDirectOrdersAction()
                .then(() => {
                    this.$store.commit("SET_IS_DIRECT_ORDERS_LOADING", false);
                })
                .catch(() => {
                    this.$store.commit("SET_IS_DIRECT_ORDERS_LOADING", false);
                });
        },

        applyGetBusinessManagersListAction() {
            this.getBusinessManagersListAction().then((response) => {
                response.data.forEach((item) => {
                    this.managerOptions.push({
                        label: item.full_name,
                        value: item.id
                    });
                });
                if (response.data.length === 0) {
                    this.businessManagerOptions.push({
                        value: "",
                        disabled: true,
                        text: "La liste des chargés d'affaire est vide",
                    });
                } else {
                    response.data.forEach((element) => {
                        this.businessManagerOptions.push(element);
                    });
                }
            });
        },

        applyOrderAssignment() {
            this.isOrderAssignmentLoading = true;
            console.log(this.orderToAssign.id);
            this.assignAnOrderToBusinessManagerAction({
                orderId: this.orderToAssign.id,
                payload: this.payload,
            })
                .then(async (response) => {
                    await this.getDirectOrdersAction();
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Succès",
                            icon: "CheckIcon",
                            text: response.data.message,
                            variant: "success",
                        },
                    }, {
                        position: "top-center",
                    });
                    this.refetchData();
                    this.isOrderAssignmentLoading = false;
                    this.$bvModal.hide("modal-order-assignment");
                    this.payload = {
                        charge_daffaire_id: "",
                    };
                    // this.$router.go()
                })
                .catch((error) => {
                    this.isOrderAssignmentLoading = false;
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Erreur",
                            icon: "AlertTriangleIcon",
                            text: error,
                            variant: "danger",
                        },
                    }, {
                        position: "top-center",
                    });
                });
        },

        sendContractByWhatsApp(data) {
            window.open(`https://wa.me/${data.user.phone_number}?text=Nos salutation!
Merci de cliquer sur le lien suivant afin de consulter le contract de prestation de votre commande ${data.recurring_service.name}. \n\n *${data.contract_file_url}*`);
        },

        sendPlecementFeesPaymentLink(data) {
            window.open(`https://wa.me/${data.user.phone_number}?text=Nos salutation!
Merci de cliquer sur le lien suivant afin de payer les frais de placement pour votre demande de *${data.recurring_service.name}*. \n\n https://ylomi.net/direct/${data.id}/placement`);
        },

        resolveOrderStatusVariant: (status, placementCostPaid, searchEmployee) => {
            if (status === -1) return "danger";
            if (status === 0 && placementCostPaid === false && searchEmployee === true) {
                return "danger";
            }
            if (status === false) return "warning";
            if (status === true) return "success";
            if (status === 0) return "warning";
            if (status === 1) return "info";
            if (status === 2) return "warning";
            if (status === 3 || status === 4) return "success";
            return "primary";
        },

        resolveStatusText: (status) => {
            if (status === 0) return "Non payé";
            if (status === 1) return "Payé";
        },

        async fetchEmployees() {
            const array = [];
            const response = await axios.get("https://jsonplaceholder.typicode.com/todos/1");
            console.table(response);
            array.push(response.data);
            return array;
        },
    },
};
</script>

<style>
.vgt-select {
    background: transparent !important;
    border-color: #404656 !important;
}
</style>

<style lang="scss" scoped>
.per-page-selector {
    width: 90px;
}

.invoice-filter-select {
    min-width: 190px;

    ::v-deep .vs__selected-options {
        flex-wrap: nowrap;
    }

    ::v-deep .vs__selected {
        width: 100px;
    }
}
</style>

<style  lang="scss" >
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>